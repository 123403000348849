<template>
  <div class="p-4 pb-0">
    <div class="flex items-center mb-4 justify-between">
      <div class="flex items-center">
        <i class="el-icon-arrow-left font-bold text-xl cursor-pointer" style="color: #999999" @click="goBack" />
        <p class="text-xl ml-4 font-bold" style="color: #ff8e3b">
          {{ categoryInfo.name }}
        </p>
      </div>
    </div>
    <div class="flex bg-white category-content">
      <div class="w-3/5 px-12 py-8 overflow-y-scroll category-info">
        <p class="font-bold text-lg mb-2">Introduction</p>
        <div class="content mb-4">{{ categoryInfo.intro }}</div>
        <!-- <p class="font-bold text-lg mb-2">Subcategories Under This Category</p> -->
        <div class="content mb-4">
          <!-- <div v-if="awardList.length > 0">
              <p v-for="(award, index) in awardList" :key="index">
                {{ award.name }}
              </p>
            </div>
            <p v-else>-</p> -->
        </div>
        <div class="content rich-text" v-html="categoryInfo.instructions"></div>
      </div>
      <div class="flex-1 px-12 py-8 overflow-y-scroll">
        <!-- <div class="mb-4">
          <p class="font-bold text-lg" style="color: #ff8e3b">
            Category Deadlines
          </p>
          <p class="mt-3">
            Entry Submission:
            {{
              categoryInfo.submission_ddl
                ? $dayjs(categoryInfo.submission_ddl * 1000).format(
                    "MMMM D, YYYY"
                  )
                : ""
            }}
          </p>
          <p class="mt-1">
            Result Announcement:
            {{
              categoryInfo.certificate_publish_time
                ? $dayjs(categoryInfo.certificate_publish_time * 1000).format(
                    "MMMM D, YYYY"
                  )
                : ""
            }}
          </p>
        </div> -->
        <div class="mb-4">
          <p class="font-bold text-lg" style="color: #ff8e3b">
            Nominate Students for This Category
          </p>
          <div>
            <p class="my-3">Method 1: Share this link with the student.</p>
            <div class="flex items-center">
              <el-input placeholder="请输入内容" v-model="shareLink" :disabled="true">
              </el-input>
              <el-button class="share-copy-link" type="primary" @click="doShare"
                :data-clipboard-text="shareLink">Copy</el-button>
            </div>
          </div>
          <div>
            <p class="my-3">Method 2: Send an email to the student.</p>
            <div class="flex items-center">
              <el-form :model="form" :rules="rules" ref="emailForm" class="w-full">
                <el-form-item prop="email" style="margin-bottom: 0" :rules="[
                  {
                    required: true,
                    message: 'Please input email',
                    trigger: 'blur',
                  },
                  // {
                  //   type: 'email',
                  //   message: 'Please input correct email',
                  //   trigger: ['blur', 'change'],
                  // },
                ]">
                  <el-input v-model="form.email" placeholder="Please input email"></el-input>
                </el-form-item>
              </el-form>
              <el-button class="share-copy-link" type="primary" @click="onSend">Send</el-button>
            </div>
          </div>
        </div>
        <div>
          <p class="font-bold text-lg" style="color: #ff8e3b">
            Recently Registered Nominees
          </p>
          <div class="flex flex-wrap mt-5 gap-5" v-if="studentList.length > 0">
            <div class="student-item flex items-center" v-for="(item, index) in studentList" :key="index"
              style="width: 100%; padding-left: 0px; padding-right: 0px">
              <!-- <img
                class="student-avatar"
                :src="
                  item.student_info.avatar_url ||
                  'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
                "
              /> -->
              <div class="flex-1">
                {{
                  `${(item.student_info.student_givenName_pinyin ||
                  item.student_info.student_givenName).charAt(0).toUpperCase() + 
                  (item.student_info.student_givenName_pinyin ||
                  item.student_info.student_givenName).slice(1)}${" "}
                ${(item.student_info.student_lastName_pinyin ||
                  item.student_info.student_lastName).charAt(0).toUpperCase() +
                  (item.student_info.student_lastName_pinyin ||
                  item.student_info.student_lastName).slice(1)}`
                }}
              </div>
              <p class="text-xs flex-1 mr-2" style="font-size: 14px">
                {{ item.student_info.student_email }}
              </p>
              <p class="text-sm flex-1">
                {{ $dayjs(item.create_time * 1000).format("MMMM D, YYYY") }}
              </p>
            </div>
          </div>
          <p class="mt-6 tips text-sm" v-else>
            No students nominated in this category have completed their
            registration.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getContactTypes,
  getSubCategoryList,
  getAdvisorRecommendedStudentsByCategoryId,
  nominateStudentByAdvisor,
} from "../../api/eae";
import { uniqBy } from "lodash-es";
import {
  getUserType,
  getAccountId,
  getUserId,
  isTestUser,
} from "../../utils/store.js";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      awardList: [],
      categoryInfo: {
        name: "",
        intro: "",
        instructions: "",
        certificate_publish_time: "",
        submission_ddl: "",
      },
      shareLink: "",
      form: {
        email: "",
      },
      studentList: [],
      rules: {
        email: [
          {
            required: true,
            message: "Please input email",
            trigger: "blur",
          },
          // {
          //   type: "email",
          //   message: "Invalid email address.",
          //   trigger: ["blur", "change"],
          // },
        ],
      },
    };
  },
  mounted() {
    let current_year = new Date().getFullYear()
    let project_code = ""
    if (current_year >= 2025) {
      project_code = current_year + 'EAE'
    } else {
      let previous_year = current_year - 1
      project_code = (previous_year % 100) + '-' + (current_year % 100) + 'EAE'
    }
    const id = this.$route.query.id;
    this.shareLink =
      "https://my.eae.international/#/form/registration?account_id=" +
      getAccountId() +
      "&project_code=" +
      project_code +
      "&contact_id=" +
      getUserId() +
      "&category_id=" +
      id;
    this.fetchData();
    this.requestAwardTitles(id)
  },
  methods: {

    requestAwardTitles(id) {
      getSubCategoryList(id).then((res) => (this.awardList = res.data.data));
    },

    goBack() {
      this.$router.push({
        // path: isTestUser() ? "/home/newCategoryList" : "/home/activityCategory",
        path: "/home/newCategoryList"
      });
    },
    goDetail(item) {
      this.$router.push({
        path: "/home/studentDetail",
        query: {
          id: item.student._id.$id,
        },
      });
    },
    onSend() {
      this.$refs["emailForm"].validate(async (valid) => {
        if (valid) {
          //getUserId() !== "62ba5a98b4ba942980737ead"
          if (false) {
            this.$message.warning("This feature is not available.");
          } else {
            if (!this.checkEmailIsValid(this.form.email)) {
              this.$message.error("Invalid email address.");
              return;
            }
            const res = await nominateStudentByAdvisor({
              student_email: this.form.email,
              category_id: this.$route.query.id,
              contact_id: getUserId()
            });
            if (res.data.code === 0) {
              this.$message.success("Email sent successfully.");
            } else {
              this.$message.error(res.data.msg || "Send email error");
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    checkEmailIsValid(email) {
      if (
        email.search(
          /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
        ) != -1
      ) {
        return true;
      }
      return false;
    },
    doShare() {
      const clipboard = new Clipboard(".share-copy-link");
      clipboard.on("success", () => {
        this.showShareDialog = false;
        this.$message.success("URL copied");
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$message.error("Copy failed");
        clipboard.destroy();
      });
    },
    async fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      try {
        const id = this.$route.query.id;
        const res = await getContactTypes(getUserId());
        const studentRes = await getAdvisorRecommendedStudentsByCategoryId({
          user_id: getUserId(),
          category_id: id,
        });
        this.categoryInfo = res.data.data.find((cat) => cat._id.$id === id);
        this.studentList = uniqBy(studentRes.data.data, "student_info._id.$id");
      } catch (error) {
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.category-content {
  height: calc(100vh - 160px);

  .content {
    white-space: pre-line;
    color: rgb(102, 102, 102);
    word-break: keep-all;
  }

  .category-info {
    border-right: 1px solid #dcdfe6;
  }
}

.student-item {
  .student-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
  }

  .view-btn {
    padding: 6px 26px;
    border-radius: 5px;
    cursor: pointer;
    background: #579eff;
    color: white;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}

.share-copy-link {
  margin-left: 12px;
}

.tips {
  color: #c0c4cc;
}
</style>

<style lang="scss">
.rich-text {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "PT Sans", "SF UI Display", ".PingFang SC", "PingFang SC",
      "Neue Haas Grotesk Text Pro", "Arial Nova", "Segoe UI", "Microsoft YaHei",
      "Microsoft JhengHei", "Helvetica Neue", "Source Han Sans SC",
      "Noto Sans CJK SC", "Source Han Sans CN", "Noto Sans SC",
      "Source Han Sans TC", "Noto Sans CJK TC", "Hiragino Sans GB", sans-serif;
    text-rendering: optimizelegibility;
    margin-bottom: 1em;
    font-weight: bold;
    line-height: 1.8rem;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
}
</style>
