import { render, staticRenderFns } from "./IFOStudentForm.vue?vue&type=template&id=5cda54db&scoped=true"
import script from "./IFOStudentForm.vue?vue&type=script&lang=js"
export * from "./IFOStudentForm.vue?vue&type=script&lang=js"
import style0 from "./IFOStudentForm.vue?vue&type=style&index=0&id=5cda54db&prod&scoped=true&lang=scss"
import style1 from "./IFOStudentForm.vue?vue&type=style&index=1&id=5cda54db&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cda54db",
  null
  
)

export default component.exports