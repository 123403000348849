<template>
  <div style="margin: 20px">
    <div class="flex justify-between items-center">
      <p class="font-bold text-lg">Student List</p>
    </div>
    <div class="mt-3">
      <el-form :inline="true" size="small" :model="form" class="demo-form-inline">
        <el-form-item label="Status" style="margin-bottom: 0; margin-right: 24px" prop="status">
          <el-checkbox-group v-model="form.status">
            <el-checkbox label="Active" name="status"></el-checkbox>
            <el-checkbox label="Pending Payment" name="status"></el-checkbox>
            <el-checkbox label="Expired" name="status"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="Year of graduation" style="margin-bottom: 0; margin-right: 24px" prop="year">
          <el-select v-model="form.year" placeholder="Select graduate year">
            <el-option v-for="(i, index) in graduationOptions" :label="i" :value="i" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Sort by" style="margin-bottom: 0" prop="sort">
          <el-radio-group v-model="form.sort">
            <el-radio label="Student Name"></el-radio>
            <el-radio label="Registration Date"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="mt-4 rounded overflow-hidden" v-for="(item, index) in studentList" :key="index">
      <div class="flex header-wrapper">
        <div class="table-header">
          {{
            `${(item.student_info.student_givenName_pinyin ||
            item.student_info.student_givenName).charAt(0).toUpperCase() + 
            (item.student_info.student_givenName_pinyin ||
            item.student_info.student_givenName).slice(1)} ${
            (item.student_info.student_lastName_pinyin ||
            item.student_info.student_lastName).charAt(0).toUpperCase() +
            (item.student_info.student_lastName_pinyin ||
            item.student_info.student_lastName).slice(1)}`
          }}
        </div>
        <div class="table-header">{{ item.student_info.student_mobile }}</div>
        <div class="table-header">{{ item.student_info.student_email }}</div>
        <div class="table-header">
          {{ `Year of Graduation: ${item.student_info.student_graduation}` }}
        </div>
        <div class="table-header">{{ item.student_info.country || "-" }}</div>
        <div :class="[
          {
            active: item.status === 'Active',
          },
          {
            expired: item.status === 'Expired',
          },
          {
            pending: item.status === 'Pending Payment',
          },
          'table-header',
        ]">
          {{ item.status }}
        </div>
      </div>
      <div class="flex content-wrapper" v-for="(i, index) in item.entries" :key="index">
        <div class="table-header">
          {{ i.project_code }}
        </div>
        <div class="entry-title">{{ i.title }}</div>
        <div class="table-header">{{ formatEntryType(i.entry_type) }}</div>
        <div class="table-header">{{ i.category.name }}</div>
        <div class="table-header">
          {{ i.sub_category ? i.sub_category.name : "-" }}
        </div>
        <div class="table-header">
          <el-button v-if="i.show_cert == 'yes'" type="warning" size="small" @click.stop="gotoShowCert(i)">
            Certificate
          </el-button>
          <el-button v-else type="primary" size="small" @click="goEntry(i)">View</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getStudentMemberShipExpiredDate,
  getStudentsByAdvisorRecommended,
} from "../../api/eae";
import { getAccountId, getUserId, ENTRY_TYPES } from "../../utils/store";
import { uniqBy } from "lodash-es";
export default {
  data() {
    return {
      ENTRY_TYPES,
      studentList: [],
      form: {
        status: ["Active", "Pending Payment", "Expired"],
        sort: "Student Name",
        year: "All",
      },
      graduationOptions: [],
    };
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        this.fetchDataByFilter(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchDataByFilter(this.form);
  },
  methods: {
    formatEntryType(entry_type) {
      if (entry_type == "team_story" || entry_type == "indie_story") {
        return 'Storyline'

      } else if (entry_type == "team_exp" || entry_type == "indie_exp") {
        return 'Single Activity'
      }
      return ENTRY_TYPES[entry_type];
    },

    checkShowCertView(info) {

      if (!info.submission_date) {
        return false;
      }

      let indie_submission_time = -2
      let indie_announce_time = -2

      let team_submission_time = -2
      let team_announce_time = -2

      let submission_date = info.submission_date//单位秒
      let entry_date = info.projectManage.entry_date
      entry_date.sort(function (a, b) {
        return a.ddl_date - b.ddl_date
      })
      let one_day = 24 * 60 * 60 * 1000 - 1
      for (let i = 0; i < entry_date.length; i++) {
        let item = entry_date[i]
        let ddl_date = 0
        let announce_date = 0
        ddl_date = parseInt((item.ddl_date + one_day) / 1000)
        announce_date = parseInt((item.announce_date) / 1000)
        if (item.app_type == 'indie' && indie_submission_time == -2) {
          if (submission_date < ddl_date) {
            indie_submission_time = ddl_date
            indie_announce_time = announce_date
          }
        }
        //团队
        if (item.app_type == 'team' && team_submission_time == -2) {
          if (submission_date < ddl_date) {
            team_submission_time = ddl_date
            team_announce_time = announce_date
          }
        }
      }

      //编辑的截止时间
      let submission_time = 0
      //公布时间
      let announce_time = 0
      if (info.application_type == '个人') {
        submission_time = indie_submission_time
        announce_time = indie_announce_time
      } else {
        submission_time = team_submission_time
        announce_time = team_announce_time
      }

      if (submission_time <= 0 || announce_time <= 0) {
        return false;
      }
      let current_time = parseInt(new Date().getTime() / 1000)

      if (current_time < announce_time) {
        return false
      }
      if (info.score) {
        let entryScore = 0
        let bonus_points = info.score.bonus_points
        let scoring_dimension = info.score.scoring_dimension
        let keys = Object.keys(scoring_dimension)
        for (let i = 0; i < keys.length; i++) {
          let score = scoring_dimension[keys[i]]
          entryScore = entryScore + parseInt(score)
        }
        let totalScore = entryScore + bonus_points
        if (totalScore >= 50) {
          return true
        }
      }
      return false;
    },
    goEntry(item) {
      this.$router.push({
        path: "/home/advisorEntry",
        query: {
          id: item._id.$id,
          studentId: item.user_id,
        },
      });
    },
    gotoShowCert(info) {
      let url = this.$router.resolve({
        path: "/certificate",
        query: {
          id: info._id.$id,
        },
      });
      window.open(url.href, "_blank");
    },
    fetchDataByFilter(filters) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getStudentsByAdvisorRecommended({
        user_id: getUserId(),
      })
        .then((res) => {
          if (res.data.code === 0) {
            const studentList = uniqBy(
              res.data.data,
              "student_info._id.$id"
            ).map((item) => ({
              ...item,
              entries: item.entries.map((i) => ({
                ...i,
                project_code: item.project_code,
              })),
            }));

            this.graduationOptions = [
              "All",
              ...uniqBy(res.data.data, "student_info._id.$id").map(
                (item) => item.student_info.student_graduation
              ),
            ];

            Promise.all(
              studentList.map((item) =>
                getStudentMemberShipExpiredDate({
                  student_id: item.student_info._id.$id,
                })
              )
            ).then((res) => {
              let sortedStudentList = studentList
                .map((item, index) => ({
                  ...item,
                  status:
                    res[index].data.data === -1
                      ? "Pending Payment"
                      : res[index].data.data == "free" ||
                        res[index].data.data * 1000 > Date.now()
                        ? "Active"
                        : "Expired",
                }))
                .filter((i) => filters.status.includes(i.status));
              if (filters.year !== "All") {
                sortedStudentList = sortedStudentList.filter(
                  (i) => i.student_info.student_graduation == filters.year
                );
              }
              if (filters.sort === "Registration Date") {
                sortedStudentList = sortedStudentList.sort(
                  (a, b) =>
                    a.student_info.create_time - b.student_info.create_time
                );
              }
              if (filters.sort === "Student Name") {
                sortedStudentList = sortedStudentList.sort(
                  (a, b) =>
                    (a.student_info.student_givenName_pinyin
                      ? a.student_info.student_givenName_pinyin + ""
                      : ""
                    ).localeCompare(
                      b.student_info.student_givenName_pinyin
                        ? b.student_info.student_givenName_pinyin + ""
                        : ""
                    )
                  // a.student_info.student_givenName_pinyin -
                  // b.student_info.student_givenName_pinyin
                );
              }
              console.log('11111')
              for (let i = 0; i < sortedStudentList.length; i++) {
                let student = sortedStudentList[i]
                student.entries.map((item) => {
                  let show_cert = this.checkShowCertView(item)
                  item.show_cert = show_cert == true ? 'yes' : 'no'
                  return item
                })
              }
              console.log('22222')
              // sortedStudentList.map((item) => {

              //   let show_cert = this.checkShowCertView(item)
              //   item.show_cert = show_cert == true ? 'show' : 'no'
              //   return item
              // })
              this.studentList = sortedStudentList;
            });
          }
        })
        .finally(() => loading.close());
    },
    goDetail(item) {
      this.$router.push({
        path: "/home/studentDetail",
        query: {
          id: item.student_info._id.$id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.student-item {
  width: calc(50% - 10px);
  background-color: white;
  padding: 18px 24px;

  .student-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 18px;
  }

  .view-btn {
    padding: 6px 26px;
    border-radius: 5px;
    cursor: pointer;
    background: #579eff;
    color: white;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}

.table-header {
  width: calc(100% / 6);
  word-wrap: break-word;
  padding: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;

  &.active {
    color: #ff6450;
    font-weight: bold;
  }

  &.pending {
    color: #67c23a;
    font-weight: bold;
  }

  &.expired {
    color: #909399;
    font-weight: bold;
  }
}

.header-wrapper {
  background: rgba(#ff6450, 0.15);
}

.content-wrapper {
  background-color: white;
}

.entry-title {
  width: calc(100% / 6);
  word-wrap: break-word;
  padding: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
</style>
