<template>
  <div style="background-color: white;min-height: 650px;padding: 20px;box-sizing: border-box;">
    <div class="product-content">
      <img class="product-logo" :src="project_info.project_logo">
      <div class="middle-content">
        <p class="product_name" style="font-size: 14px;margin-bottom: 4px">{{ project_info.project_ename }}</p>
        <div style="display: flex;gap:5px;">
          <div v-if="project_info.earlybird_deadline_date" class="price-container">
            <div>
              <p class="font_red font_bold">Early Bird Registration</p>
              <p class="price-date">DDL: {{ $dayjs(project_info.earlybird_deadline_date).format("MMMM DD,YYYY") }}</p>
            </div>
            <p class="price">Fee: {{ project_info.project_earlybird_price }} {{
              project_info.project_price_currency }}</p>
          </div>
          <div class="price-container">
            <div>
              <p class="font_red font_bold">Normal Registration</p>
              <p class="price-date">DDL: {{ $dayjs(project_info.deadline_date).format("MMMM DD, YYYY") }}</p>
            </div>
            <p class="price">Fee: {{ project_info.project_price == 0 ? 'Free' : project_info.project_price + ' ' +
              project_info.project_price_currency }}</p>
          </div>
          <div v-if="project_info.project_price !== 0" class="price-container">
            <div>
              <p class="font_red font_bold">Fee Reduction</p>
            </div>
            <p class="price-reduction font_red"><span @click="showFeeReduction"
                :class="price_policy ? 'font_under_line' : ''" style="font-family: centurygothic;">{{
                  reduction_level1 }} to {{ reduction_level8 }} {{ project_info.project_price_currency }}</span></p>
          </div>
        </div>
      </div>
      <div class="right-content">
        <div class="btn-sign" v-if="checkSignStatus()" :class="checkAccount == 0 ? 'btn-disabled' : ''"
          @click="showSignUpDialog">{{ checkAccount == 0 ? 'Signed Up' : 'Sign Up' }}</div>
        <div class="btn-disabled" v-if="checkClosed()">Closed</div>
      </div>
    </div>
    <div style="margin-top: 30px;">
      <el-row :gutter="20">
        <el-col :span="16">
          <div>
            <p class="title">Introduction</p>
            <p v-html="project_info.project_introduction" class="intro_container ql-snow ql-editor"></p>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <p class="title">Announcements & Updates</p>
            <div class="announce-container">
              <div v-for="(announce, index) in news" :key="index" class="announce-item"
                @click="doShowNewsInfo(announce)">
                <p class="announce-time">{{ $dayjs(announce.update_time * 1000).format('MMM D, YYYY') }}</p>
                <p class="announce-title">{{ announce.title }}</p>
                <p class="announce-content" v-html="announce.content"></p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog :visible.sync="isShowDialog" title="View" width="80%">
      <div class="disable-mouse-events">
        <iframe :src="price_policy + '#toolbar=0'" width="100%" height="600px"></iframe>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="isShowSignUpDialog" :title="'Sign Up for ' + project_info.project_ename" width="800px"
      @close="closeDialog">
      <div>
        <el-form :inline="true" ref="form">
          <el-form-item label="Select a program advisors">
            <el-select v-model="selectContactIds" placeholder="Please select" :multiple="true" style="width: 400px;">
              <el-option v-for="(contact, index) in teacherOpt" :key="index" :label="formatContactName(contact)"
                :value="contact._id.$id">
              </el-option>
            </el-select>
          </el-form-item>
          <p>*Emails to students for the project will also go to the assigned program advisor.</p>
        </el-form>
        <div style="width: 100%; text-align: center; margin-top: 30px">
          <el-button size="medium" @click="isShowSignUpDialog = false">Cancel</el-button>
          <el-button type="primary" size="medium" @click="saveInfo(formData)">Confirm</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showNewsDialog" width="45%" :title="selectNews.title">
      <div style="margin: 10px">
        <p v-html="selectNews.content" style="color: #666;font-size: 14px;line-height: 20px;"></p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  updateAccountApplicationContacts,
  getNewsUpdateListByProject,
  getAccountReductionLevelByProject,
  getREProjectInfo,
  checkAccountApplication,
  addAccountApplication, getContactsByAccount,
} from '../../api/eae'
import {
  getUserId,
  getUserType,
  getAccountId,
} from '../../utils/store'
import '../../assets/common/font.css'
export default ({
  name: 'index',
  data() {
    return {
      user_type: getUserType(),
      project_id: this.$route.query.project_id,
      from: this.$route.query.from,
      project_info: {},
      productPrice: {},
      reduction_level: 0,
      reduction_level1: 0,
      reduction_level8: 0,
      reduction_price: 0,
      price_policy: '',
      news: [],
      checkAccount: null,
      isShowDialog: false,
      isShowSignUpDialog: false,
      teacherOpt: [],
      showNewsDialog: false,
      selectNews: {},
      selectContactIds: []
    }
  },
  mounted() {
    this.fetchData()
    this.getSubjectList()
  },
  methods: {

    formatContactName(contact) {
      const capitalizeWords = str => str.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');

      if (contact.contact_enName) {
        return capitalizeWords(contact.contact_enName);
      }
      
      const lastName = capitalizeWords(contact.contact_lastName);
      const givenName = capitalizeWords(contact.contact_givenName);
      return `${lastName} ${givenName}`;
    },

    checkSignStatus() {
      if (this.project_info.deadline_date && this.project_info.deadline_date != '') {
        let deadline_date = this.project_info.deadline_date
        let current_time = new Date().getTime()
        if (deadline_date > current_time) {
          return true
        }
      }
      return false
    },
    checkClosed() {
      if (this.project_info.deadline_date && this.project_info.deadline_date != '') {
        let deadline_date = this.project_info.deadline_date
        let current_time = new Date().getTime()
        if (deadline_date <= current_time) {
          return true
        }
      }
      return false
    },

    getSubjectList() {
      this.teacherOpt = []
      getContactsByAccount(getAccountId()).then((res) => {
        if (res.data.length > 0) {
          this.teacherOpt = res.data
        }
      })
    },
    async fetchData() {

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let projectResult = await getREProjectInfo(this.project_id)
      this.project_info = projectResult.data

      let newsResult = await getNewsUpdateListByProject(this.project_id)
      this.news = newsResult.data.sort((a, b) => {
        return b.update_time - a.update_time
      })
      let reductionLevelResult = await getAccountReductionLevelByProject(getAccountId())
      let reduction_level = reductionLevelResult.data

      await checkAccountApplication(getAccountId(), this.project_id).then((res) => {
        this.checkAccount = res.data.code
      })

      for (let i = 0; i < this.project_info.price_reductions.length; i++) {
        let price = this.project_info.price_reductions[i]
        if (price.level == reduction_level) {
          this.reduction_price = price.reduction_price
          break
        }
      }
      this.reduction_level1 = this.project_info.price_reductions[0].reduction_price
      this.reduction_level8 = this.project_info.price_reductions[7].reduction_price
      this.price_policy = this.project_info.price_policy

      loading.close()
    },

    showSignUpDialog() {
      //如果是advisor登录，点击创建报名表，然后把当前老师设置为项目负责人
      if (this.user_type == 'advisor') {
        if (false) {
          let content = 'You have signed up for the ' + this.project_info.project_ename + ' successfully. Please navigate to "Registered Programs" for further steps.';
          this.$alert(content, 'Sign Up Successful', {
            confirmButtonText: 'Confirm',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            callback: action => {
              if (action == 'confirm') {
                if (this.from == 'student') {
                  this.$router.replace({
                    path: '/home/studentRegistrations'
                  })
                } else {
                  this.$router.replace({
                    path: '/home/schoolRegistrations'
                  })
                }
              }
            }
          })
          return
        }

        addAccountApplication(getAccountId(), this.project_id).then((res) => {
          let application = res.data.data
          let application_contacts = []
          if (application.application_contacts && application.application_contacts.length > 0) {
            application_contacts = application.application_contacts
          }
          let contact_id = getUserId()
          if (application_contacts.includes(contact_id) == false) {
            application_contacts.push(contact_id)
          }
          let application_id = application._id.$id
          updateAccountApplicationContacts(application_id, application_contacts).then((res) => {
            let content = 'You have signed up for the ' + this.project_info.project_ename + ' successfully. Please navigate to "Registered Programs" for further steps.';
            this.$alert(content, 'Sign Up Successful', {
              showCancelButton: true,
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              callback: action => {
                if (action == 'confirm') {
                  if (this.from == 'student') {
                    this.$router.replace({
                      path: '/home/studentRegistrations'
                    })
                  } else {
                    this.$router.replace({
                      path: '/home/schoolRegistrations'
                    })
                  }
                }
              }
            })
            this.fetchData()
          })
        })
        return
      }


      if (this.checkAccount == 0) {
        return
      }
      this.selectContactIds = []
      this.isShowSignUpDialog = true
    },

    closeDialog() {
    },
    saveInfo() {
      if (this.selectContactIds.length <= 0) {
        this.$message.warning('Please select advisors')
        return
      }
      let newContacts = this.selectContactIds
      addAccountApplication(getAccountId(), this.project_id).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.saveAppContacts(result.data._id.$id, newContacts)
        } else {
          this.$message.warning(result.msg)
        }
      })
    },
    saveAppContacts(application_id, contacts) {
      updateAccountApplicationContacts(application_id, contacts).then((res) => {
        this.$message.success('Sign up success~')
        this.isShowSignUpDialog = false
        this.fetchData()
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    showFeeReduction() {
      if (this.price_policy) {
        this.isShowDialog = true
      }
    },
    doShowNewsInfo(news) {
      this.selectNews = news
      this.showNewsDialog = true
    },
  }
})
</script>
<style scoped lang="scss">
.product-content {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #E6D7D7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 16px;
  display: flex;

  .product-logo {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .middle-content {
    padding: 0px 10px;
    box-sizing: border-box;
    font-size: 14px;

    .price-container {
      border-radius: 5px;
      padding: 6px 10px;
      //box-sizing: border-box;
      background: #F8F8F8;

      .price-date {
        font-family: centurygothic;
      }

      .price {
        font-family: centurygothic;
      }

      .price-reduction {
        margin-top: 10px;
        font-family: centurygothic;
      }

    }


  }

  .right-content {
    flex: 1;
    text-align: right;
    padding: 0px 10px;
    box-sizing: border-box;

    .btn-sign {
      display: inline-block;
      padding: 12px 0;
      width: 110px;
      text-align: center;
      background-color: #ff6450;
      border-radius: 5px;
      box-sizing: border-box;
      color: white;
      font-size: 15px;
      cursor: pointer;
    }

    .desc {
      margin-top: 10px;
      color: #4A0700;
      font-size: 14px;
      font-family: centurygothic;
      line-height: 20px;
    }

    .btn-disabled {
      color: #999;
      background-color: #e2e2e2;
      border: 1px solid #e2e2e2;
    }
  }
}


.product_name {
  margin-left: 10px;
  color: #333;
  font-size: 20px;
  font-family: centurygothic_bold;
}

.title {
  color: #333;
  font-size: 16px;
  font-family: centurygothic_bold;
  margin-bottom: 15px;
}

.intro_container {
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #F0E1E1;
  font-size: 14px;
  color: #333;
  line-height: 22px;
  padding: 20px 20px;
  font-family: centurygothic;
}


.announce-container {
  width: 100%;

  .announce-item {
    cursor: pointer;
    background-color: #F8F8F8;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 20px;

    .announce-time {
      color: var(--c-primary);
      font-size: 14px;
      font-family: centurygothic_bold;
    }

    .announce-title {
      margin-top: 20px;
      color: #333;
      font-size: 14px;
      font-family: centurygothic_bold;
    }

    .announce-content {
      margin-top: 10px;
      color: #999;
      font-size: 12px;
      line-height: 18px;
      font-family: centurygothic;
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      /* 定义显示的行数 */
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

}

.font_bold {
  font-weight: bold;
}

.font_red {
  color: var(--c-primary);
}

.font_under_line {
  text-decoration: underline 1px solid var(--c-primary);
  cursor: pointer;
}
</style>
