<template>
  <div>
    <div class="content-top">
      <p class="font-bold mt-3 text-lg">Teacher List</p>
      <el-button class="create_btn" type="danger" @click="btnCreate('Add')">Add a Teacher</el-button>
    </div>
    <div style="padding: 20px;">
      <el-table :data="dataList" style="font-size: 12px;box-sizing: border-box;" empty-text="No Data"
                :highlight-current-row="false" :highlight-selection-row="false"
                :header-cell-class-name="'custom-head-cell'"
                :cell-style="tableCellstyle" :row-class-name="'custom-row-bg'">
        <!--            <el-table-column prop="index" type="index" label="Index" width="100px"></el-table-column>-->
        <el-table-column prop="contact_givenName" label="Name">
          <template slot-scope="scope">
            {{ scope.row.contact_enName ? scope.row.contact_enName :
              scope.row.contact_givenName + ' ' + scope.row.contact_lastName }}
          </template>
        </el-table-column>
        <el-table-column prop="contact_email" label="Email" ></el-table-column>
        <el-table-column prop="contact_prefix" label="Gender" >
          <template slot-scope="scope">
            {{scope.row.contact_prefix=='男'?'Male':'Female'}}
          </template>
        </el-table-column>
        <el-table-column prop="contact_discipline" label="Subject"></el-table-column>
        <el-table-column prop="contact_position" label="Position"></el-table-column>
        <el-table-column label="Operation" width="280px">
          <template slot-scope="scope">
            <el-button type="danger" @click="btnCreate('Edit',scope.row)">Edit</el-button>
            <el-button type="danger" @click="btnRemove(scope.row)">Disable Account</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        :visible.sync="isShowDialog"
        :title="dialogTitle == 'Edit'? 'Edit Teacher Information':'Add a Teacher'"
        width="800px"
        @close="closeDialog"
    >
      <div>
        <el-form :inline="true" ref="form" :rules="rules" :model="formData" label-width="140px">
          <el-form-item label="Given Name (EN)" prop="contact_givenName">
            <el-input v-model="formData.contact_givenName" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Last Name (EN)" prop="contact_lastName">
            <el-input v-model="formData.contact_lastName" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="contact_email">
            <el-input v-model="formData.contact_email" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Gender" prop="contact_prefix">
            <el-select v-model="formData.contact_prefix" placeholder="Please select" style="width: 220px;">
              <el-option
                  v-for="item in genderList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Subject" prop="contact_discipline">
            <el-select v-model="formData.contact_discipline" placeholder="Please select" style="width: 220px;">
              <el-option
                  v-for="item in subjectList"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Position" prop="contact_position">
            <el-input v-model="formData.contact_position" style="width: 220px;"></el-input>
          </el-form-item>
        </el-form>
        <div style="width: 100%; text-align: center; margin-top: 30px">
          <el-button size="medium" @click="isShowDialog=false">Cancel</el-button>
          <el-button type="primary" size="medium" @click="saveInfo(formData)">Confirm</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  addContactRecommend,
  getContactsByAccount, removeAccountContact, updateAccountContact, getContactSubjects
} from '../../api/eae'
import {
  getUserId,
  getTargetType
} from '../../utils/store'

export default ({
  name: 'index',
  data() {
    return {
      dataList: [],
      genderList: [{
        label:'Male',
        value:'男',
      },{
        label:'Female',
        value:'女',
      }],
      subjectList: [],
      formData: {},
      rules: {
        contact_givenName: [
          {required: true, message: 'Please input the name', trigger: 'blur'},
        ],
        contact_lastName: [
          {required: true, message: 'Please input the name', trigger: 'blur'},
        ],
        contact_email: [
          {required: true, message: 'Please input the email', trigger: 'blur'},
          {type: 'email', message: 'Please input the correct email address', trigger: ['blur', 'change']}
        ],
        contact_prefix: [
          {required: true, message: 'Please select', trigger: 'blur'},
        ],
        contact_discipline: [
          {required: true, message: 'Please select', trigger: 'blur'},
        ]
      },
      isShowDialog: false,
      dialogTitle: 'Add'
    }
  },
  mounted() {
    this.fetchData()
    this.getSubjectList()
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getContactsByAccount(getUserId()).then((res) => {
        this.dataList = res.data
        loading.close()
      })
    },
    tableCellstyle() {
      return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
    },
    getSubjectList() {
      getContactSubjects(getUserId()).then((res)=>{
        this.subjectList = res.data
      })
    },
    btnCreate(title,data){
      this.isShowDialog = true
      this.dialogTitle = title
      title == 'Edit' ? this.formData.id = data._id.$id : this.formData.id = ''
      if(title == 'Edit'){
        this.formData= {
          contact_givenName:data.contact_givenName,
          contact_lastName:data.contact_lastName,
          contact_email:data.contact_email,
          contact_prefix:data.contact_prefix,
          contact_discipline:data.contact_discipline,
          contact_position:data.contact_position,
          id:data._id.$id
        }
      }
      console.log(this.formData,'formData')
    },
    saveInfo(data){
      console.log(data,'-=-=-=-')
      this.$refs.form.validate((valid) => {
        if(valid){
          updateAccountContact(
              data.contact_givenName,
              data.contact_lastName,
              data.contact_email,
              data.contact_prefix,
              data.contact_discipline,
              data.contact_position,
              getUserId(),
              data.id
          ).then((res)=>{
            console.log(res.data,'添加成功')
            this.isShowDialog = false
            this.formData = {}
            this.fetchData()
          })
        }
      })
    },
    btnRemove(data) {
      console.log(data, 'remove DATA')
      this.$confirm(
          "Are you sure you want to remove student_name from the project_name？",
          "Remove Students",
          {
            type: "warning",
            confirmButtonText: "Confirm",
          }).then(()=>{
            removeAccountContact(data._id.$id).then((res)=>{
              this.fetchData()
            })
        console.log(123)
      }).catch(() => {

      });

    },
    closeDialog() {
      console.log(123432)
      this.formData = {}
    }
  }
})
</script>
<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
  background: #FAE3E4;
}

/deep/ .el-table tbody .cell {
  font-family: Ubuntu-Light;
}

/deep/ .el-table .custom-head-cell {
  background: var(--c-primary);
  color: white;
  text-align: center;
  border: 1px solid white;
  box-sizing: border-box;
  font-family: Ubuntu-Bold;
}

.content-top {
  position: relative;
  margin: 0 20px;
}

.create_btn {
  position: absolute;
  top: 0;
  right: 0;
}
</style>