<template>
    <div style="width: 100vw;height: 100vh;background-color: #043280;overflow-y: auto;font-family: GravityNormal">
        <div class="accountform">
            <div style="display: flex;width: 100%;align-items: flex-start;">
                <div style="display: flex;flex: 1;align-items: flex-start;">
                    <img src="../../assets/image/logo.png" style="width: 240px;height: auto;margin-right: 32px" />
                    <img src="../../assets/image/wao_logo.png" style="width: 160px;height: auto;object-fit: cover;" />
                </div>
                <p style="width: 300px;font-size: 12px;text-align: right;color: #666;line-height: 20px;">
                    Extracurricular Activity Excellence (EAE) International stands as a global partner to the World Academic Olympics (WAO), entrusted with the role of its operation.</p>
            </div>

            <div class="title1">Organization Registration for {{form.project_code}}</div>
            <div class="title1" style="font-size: 12px;margin-top: 5px;">please fill out this form in English</div>
            <div style="color: #ff0000; font-size: 14px; margin: 20px 0; text-align: center; width: 100%; padding: 10px; background-color: #ffe6e6; border-radius: 4px;">
                This registration form is only for ADVISERS/TEACHERS to register for their organizations. <br>If you are <strong>an individual STUDENT or a STUDENT team leader</strong>, please use the<a href="/#/Form/wao" style="color: #ff0000; text-decoration: underline;"> Student Registration Form</a> instead.
            </div>

            <el-form :model="form" :rules="formRules" ref="form"
                style="width: 100%;padding: 0px 20px;box-sizing: border-box;" label-position="top">

                <p style="text-align: left;color: #043280; font-size: 16px; font-weight: bold;margin: 20px 0px;">
                    Registrant Information</p>

                <div class="row">
                    <div class="item">
                        <el-form-item>
                            <el-form-item label="First Name (in English)" prop="contact_givenName">
                                <el-input v-model="form.contact_givenName" placeholder="Please input"></el-input>
                            </el-form-item>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <el-form-item label="Last Name (in English)" prop="contact_lastName">
                                <el-input v-model="form.contact_lastName" placeholder="Please input"></el-input>
                            </el-form-item>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="item">
                        <el-form-item label="Title" prop="contact_prefix">
                            <el-select v-model="form.contact_prefix" placeholder="Please select" style="width: 100%;">
                                <el-option value="Mr.">Mr.</el-option>
                                <el-option value="Mrs.">Mrs.</el-option>
                                <el-option value="Ms.">Ms.</el-option>
                                <el-option value="Dr.">Dr.</el-option>
                                <el-option value="Prof.">Prof.</el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="Position" prop="contact_position">
                            <el-input v-model="form.contact_position" placeholder="Please input"></el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="item">
                        <el-form-item label="Email" prop="contact_email">
                            <el-input v-model="form.contact_email" placeholder="Please input"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="School Name (EN)" prop="account_nameEn">
                            <el-input v-model="form.account_nameEn" placeholder="Please input"></el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="item">
                        <el-form-item label="Organization Type" prop="organization_type">
                            <el-select v-model="form.organization_type" placeholder="Please select" style="width: 100%;">
                                <el-option value="Public School">Public School</el-option>
                                <el-option value="Private School">Private School</el-option>
                                <el-option value="International School">International School</el-option>
                                <el-option value="Educational Institution">Educational Institution</el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="How did you know about us?" prop="know_sources">
                            <el-select v-model="form.know_sources" multiple placeholder="Please select" style="width: 100%;">
                                <el-option value="Social Media">Social Media</el-option>
                                <el-option value="Search Engine">Search Engine</el-option>
                                <el-option value="Email">Email</el-option>
                                <el-option value="Friend">Friend</el-option>
                                <el-option value="School">School</el-option>
                                <el-option value="Others">Others</el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="item">
                        <el-form-item label="Curriculum Type" prop="curriculum_types">
                            <el-select v-model="form.curriculum_types" multiple placeholder="Please select" style="width: 100%;">
                                <el-option value="National Curriculum">National Curriculum</el-option>
                                <el-option value="IB">IB</el-option>
                                <el-option value="A-Level">A-Level</el-option>
                                <el-option value="AP">AP</el-option>
                                <el-option value="Others">Others</el-option>
                            </el-select>
                        </el-form-item>
                        <div v-if="checkShowOthersView()" style="margin-top: 10px;">
                            <el-input v-model="form.other_curriculum_type" placeholder="Please specify other curriculum type"></el-input>
                        </div>
                    </div>
                    <div class="item">
                        <el-form-item label="Annual Tuition" prop="annual_tuition">
                            <el-select v-model="form.annual_tuition" placeholder="Please select" style="width: 100%;">
                                <el-option value="> 11,000 USD">> 11,000 USD</el-option>
                                <el-option value="> 6,000 USD">> 6,000 USD</el-option>
                                <el-option value="> 3,000 USD">> 3,000 USD</el-option>
                                <el-option value="> 1,500 USD">> 1,500 USD</el-option>
                                <el-option value="> 1,100 USD">> 1,100 USD</el-option>
                                <el-option value="> 750 USD">> 750 USD</el-option>
                                <el-option value="> 350 USD">> 350 USD</el-option>
                                <el-option value="< 350 USD and > 0">< 350 USD and > 0</el-option>
                                <el-option value="Free">Free</el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="item">
                        <el-form-item label="Country/Region" prop="account_country">
                            <el-select v-model="form.account_country" style="width: 100%;" placeholder="Please select" filterable>
                                <el-option v-for="(country, index) in countryList" :key="index"
                                    :label="country.country_en" :value="country.country_en"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="State/Province" prop="account_province">
                            <el-input v-model="form.account_province" placeholder="Please input"></el-input>
                        </el-form-item>
                    </div>
                </div>

                <el-form-item label="Address" prop="account_address">
                    <el-input v-model="form.account_address" placeholder="Please input"></el-input>
                </el-form-item>

                <p style="text-align: left;color: #043280; font-size: 16px; font-weight: bold;margin: 20px 0px;">
                    Advisor Information</p>

                <el-form-item label="Will you be the advisor for the WAO Program in your school/institution?" prop="contact_as_adviser">
                    <el-radio v-model="form.contact_as_adviser" label="YES"></el-radio>
                    <el-radio v-model="form.contact_as_adviser" label="NO" style="margin-left: 100px;"></el-radio>
                </el-form-item>

                <template v-if="form.contact_as_adviser === 'NO'">
                    <p style="color: #666; font-size: 14px; line-height: 20px; margin-bottom: 20px;">If you are not the advisor for WAO Program, please provide the information of the right advisor.</p>
                    <div class="row">
                        <div class="item">
                            <el-form-item label="Advisor's First Name" style="flex: 1;" prop="adviser_givenName">
                                <el-input v-model="form.adviser_givenName" placeholder="Please input"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item label="Advisor's Last Name" style="flex: 1;" prop="adviser_lastName">
                                <el-input v-model="form.adviser_lastName" placeholder="Please input"></el-input>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row">
                        <div class="item">
                            <el-form-item label="Advisor's Position/Subject" style="flex: 1;" prop="adviser_subject">
                                <el-input v-model="form.adviser_subject" placeholder="Please input"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item label="Advisor's Email" style="flex: 1;" prop="adviser_email">
                                <el-input v-model="form.adviser_email" placeholder="Please input"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </template>

                <!-- Agreement section -->
                <div class="terms flex items-center justify-center" style="margin-top: 20px;">
                    <div @click="isAgree = !isAgree" style="display: flex; align-items: center">
                        <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-if="!isAgree"
                            :src="require('../../assets/image/unchecked.png')" />
                        <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-else
                            :src="require('../../assets/image/checked.png')" />
                    </div>
                    <p style="line-height: 20px;"> I agree that EAE may use my provided information to set up a personal account and <br> to email me about notifications, reminders, and extracurricular opportunities.</p>
                </div>

                <div class="submit">
                    <div class="submitBtn" @click="submit">Submit</div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import {
    getCountries,
    saveAccountFormV1
} from '../../api/eae'
import '../../assets/common/font.css'

export default ({
    name: 'index',
    data() {
        return {
            isAgree: false,
            countryList: [],
            form: {
                BU: "WAO",
                project_code: "2025WAO",
                new_field: 'yes',
                account_country: '',
                account_name: '',
                account_nameEn: '',
                account_province: '',
                account_address: '',
                organization_type: '',

                curriculum_types: [],
                other_curriculum_type: '',
                annual_tuition: '',

                contact_lastName: '',
                contact_givenName: '',
                contact_prefix: '',
                contact_email: '',
                contact_position: '',

                know_sources: [],
                contact_as_adviser: '',

                adviser_lastName: '',
                adviser_givenName: '',
                adviser_subject: '',
                adviser_email: '',
            },
            formRules: {
                annual_tuition: [{ required: true, message: "Please select", trigger: "blur" }],
                curriculum_types: [
                    { required: true, message: "Please select", trigger: "blur" },
                ],
                know_sources: [{ required: true, message: "Please select", trigger: "blur" }],
                contact_lastName: [{ required: true, message: "Please input", trigger: "blur" }],
                contact_givenName: [{ required: true, message: "Please input", trigger: "blur" }],
                contact_prefix: [{ required: true, message: "Please select", trigger: "blur" }],
                contact_email: [{ required: true, message: "Please input", trigger: "blur" }],
                contact_position: [{ required: true, message: "Please input", trigger: "blur" }],

                account_nameEn: [{ required: true, message: "Please input", trigger: "blur" }],
                organization_type: [{ required: true, message: "Please select", trigger: "blur" }],
                account_country: [{ required: true, message: "Please select", trigger: "blur" }],
                account_province: [{ required: true, message: "Please input", trigger: "blur" }],
                account_address: [{ required: true, message: "Please input", trigger: "blur" }],

                contact_as_adviser: [{ required: true, message: "Please select", trigger: "blur" }],
                adviser_givenName: [{ 
                    required: true, 
                    message: "Please input", 
                    trigger: "blur",
                    validator: (rule, value, callback) => {
                        if (this.form.contact_as_adviser === 'NO' && !value) {
                            callback(new Error('Please input'));
                        } else {
                            callback();
                        }
                    }
                }],
                adviser_lastName: [{ 
                    required: true, 
                    message: "Please input", 
                    trigger: "blur",
                    validator: (rule, value, callback) => {
                        if (this.form.contact_as_adviser === 'NO' && !value) {
                            callback(new Error('Please input'));
                        } else {
                            callback();
                        }
                    }
                }],
                adviser_subject: [{ 
                    required: true, 
                    message: "Please input", 
                    trigger: "blur",
                    validator: (rule, value, callback) => {
                        if (this.form.contact_as_adviser === 'NO' && !value) {
                            callback(new Error('Please input'));
                        } else {
                            callback();
                        }
                    }
                }],
                adviser_email: [{ 
                    required: true, 
                    message: "Please input", 
                    trigger: "blur",
                    validator: (rule, value, callback) => {
                        if (this.form.contact_as_adviser === 'NO' && !value) {
                            callback(new Error('Please input'));
                        } else {
                            callback();
                        }
                    }
                }]
            }
        }
    },
    mounted() {
        this.requestCountries()
    },
    methods: {
        // Same methods as IFO form
        checkShowOthersView(){
            return this.form.curriculum_types.includes('Others')
        },

        requestCountries() {
            getCountries().then((res) => {
                this.countryList = res.data
            })
        },

        submit() {
            if (!this.isAgree) {
                this.$message.warning("You must agree to the statement.");
                return;
            }

            this.$confirm(
                'Please confirm that you are registering as an advisor/teacher for your organization, NOT as a student. Students should use the Student Registration Form instead.',
                'Confirmation Required',
                {
                    confirmButtonText: 'Yes, I am an advisor/teacher',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            ).then(() => {
                this.$refs.form.validate((val) => {
                    if (val) {
                        saveAccountFormV1(this.form).then((res) => {
                            if (res) {
                                this.$confirm("Registration received. We'll set up your account and contact you via email within 3-5 business days.", 'Registration Successful', {
                                    showCancelButton: false,
                                    confirmButtonText: 'OK',
                                    type: 'success'
                                })
                            }
                        });
                    }
                });
            }).catch(() => {
                // User cancelled the confirmation
            });
        }
    }
})
</script>

<style scoped lang="scss">
// Update all color references from #E8CE60 to #043280
::-webkit-scrollbar {
    display: none;
}

.accountform {
    width: 1000px;
    margin: 0 auto;
    background: #fff;
    padding: 30px 40px 20px;
    box-sizing: border-box;

    .title1 {
        margin-top: 20px;
        font-family: GravityBold;
        color: #043280;
        font-size: 22px;
        margin-bottom: 10px;
        align-items: center;
        width: 100%;
        font-weight: bold;
        text-align: center;
    }

    .row {
        display: flex;
        justify-content: space-between;

        .item {
            width: 420px;
        }
    }

    .submit {
        .submitBtn {
            font-family: GravityBold;
            width: 280px;
            height: 50px;
            background: #043280;
            border-radius: 25px;
            text-align: center;
            line-height: 50px;
            color: #fff;
            font-size: 20px;
            margin: 0 auto;
            margin-top: 20px;
            cursor: pointer;
        }
        margin-bottom: 50px;
    }
}

/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
    border-color: #043280;
}

/deep/ .el-input__inner:hover {
    border-color: #043280;
}

/deep/ .el-input__inner:focus {
    border-color: #043280;
}

/deep/ .el-form-item__label {
    font-size: 16px;
    color: #000000;
}

/deep/ .el-checkbox__label {
    font-size: 16px;
    color: #000000;
    font-weight: normal;
}

/deep/ .el-radio__label {
    font-size: 16px;
    color: #000000;
    font-weight: normal;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #043280;
    border-color: #043280;
}

/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #043280;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    background-color: #043280;
    border-color: #043280;
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #043280;
}
</style> 