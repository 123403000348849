<template>
    <div style="padding: 20px;width: 100%;box-sizing: border-box;">
        <div class="flex justify-between items-center">
            <p class="font-bold text-lg">Entry List</p>
        </div>
        <div class="mt-4 ">
            <div class="table-header header-wrapper">
                <p class="project_code">EAE Program</p>
                <p class="recommend">R</p>
                <p class="entry_title">Entry Title</p>
                <p class="category">Category</p>
                <p class="type">Type</p>
                <p class="owner">Owner</p>
                <p class="lastUpdatedDate">Last Updated Date</p>
                <p class="status_results">Status/Results</p>
            </div>
            <div v-if="dataList.length > 0">
                <div v-for="(entry, index) in dataList" :key="index" class="table-header entry-item">
                    <p class="project_code">{{ entry.project_code }}</p>
                    <div class="recommend">
                        <img v-if="entry.contact_id && entry.contact_id != ''" src="../../assets/image/icon_starred.png"
                            style="width: 24px;height: auto;">
                    </div>
                    <p class="entry_title">{{ entry.title }}</p>
                    <p class="category">{{ entry.category.name }}</p>
                    <p class="type">{{ formatEntryType(entry.entry_type) }}</p>
                    <p class="owner">{{ (entry.student.student_givenName_pinyin.charAt(0).toUpperCase() + entry.student.student_givenName_pinyin.slice(1)) + ' ' + (entry.student.student_lastName_pinyin.charAt(0).toUpperCase() + entry.student.student_lastName_pinyin.slice(1)) }}
                    </p>
                    <p class="lastUpdatedDate">{{ formatUpdaeTime(entry.update_time) }}</p>
                    <div class="status_results" style="cursor: pointer;" @click="gotoTargetView(entry)">
                        <p v-if="entry.entry_result == 'Superior' || entry.entry_result == 'Distinctive' || entry.entry_result == 'Excellent'"
                            class="mt-3 btn-cert">{{ entry.entry_result }}</p>
                        <p v-else-if="entry.entry_result == 'Results'" class="btn-cert" style="background: #579eff">{{
                            entry.entry_result }}</p>
                        <p v-else>{{ entry.entry_result }}</p>
                    </div>
                </div>
            </div>
            <el-empty v-else description="No Data"></el-empty>
        </div>
    </div>
</template>
<script>
import {
    getAdvisorStudentEntryList
} from '../../api/eae'
import {
    ENTRY_TYPES,
    getUserId
} from '../../utils/store'

export default ({
    name: 'index',
    data() {
        return {
            dataList: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        gotoTargetView(entry) {
            if (entry.entry_result == 'Results') {//成绩页面
                this.$router.push({
                    path: '/home/newScore',
                    query: {
                        id: entry._id.$id
                    }
                })
                return
            }
            if (entry.entry_result == 'Superior' || entry.entry_result == 'Distinctive' || entry.entry_result == 'Excellent') {
                //证书页面
                let url = this.$router.resolve({
                    path: '/certificate',
                    query: {
                        id: entry._id.$id
                    }
                })
                window.open(url.href, '_blank')
                return
            }
            this.$router.push({
                path: "entryEdit",
                query: {
                    id: entry._id.$id
                },
            });
        },

        fetchData() {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.dataList = []
            getAdvisorStudentEntryList(getUserId()).then((res) => {
                let result = res.data.data
                for (let i = 0; i < result.length; i++) {
                    let entry = result[i]
                    let projectManage = entry.projectManage
                    //证书发布时间
                    let entry_result = ''
                    // let publish_time = entry.category.certificate_publish_time
                    // //编辑截止时间
                    // let submission_ddl = entry.category.submission_ddl
                    //采用项目配置时间
                    let entry_type = entry.entry_type
                    let publish_time = 0
                    let submission_ddl = 0
                    if(entry_type == 'indie_exp' || entry_type == 'indie_story') {
                        publish_time = projectManage.indie_announce_date
                        submission_ddl = projectManage.indie_submission_date
                    }else {
                        publish_time = projectManage.team_announce_date
                        submission_ddl = projectManage.team_submission_date
                    }
                    let current_time = parseInt(new Date().getTime() / 1000)
                    if (publish_time > current_time) {
                        if (current_time > submission_ddl) {
                            entry_result = 'Editing in Progress'
                        } else {
                            entry_result = 'Under Review'
                        }
                    } else {
                        entry_result = this.calcScore(entry)
                    }
                    entry['entry_result'] = entry_result
                    this.dataList.push(entry)
                }
                loading.close()
            })
        },
        formatEntryType(type) {
            if (type == 'team_exp') {
                return 'T-WE'
            }
            if (type == 'team_story') {
                return 'T-S'
            }
            if (type == 'indie_story') {
                return 'I-S'
            }
            if (type == 'indie_exp') {
                return 'I-WE'
            }
        },
        formatUpdaeTime(update_time) {
            var date = new Date(update_time * 1000);
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            return this.formatMonthToEng(month) + " " + day + ", " + year;
        },
        formatMonthToEng(month) {
            switch (month) {
                case 1:
                    return "Jan";
                case 2:
                    return "Feb";
                case 3:
                    return "Mar";
                case 4:
                    return "Ari";
                case 5:
                    return "May";
                case 6:
                    return "Jun";
                case 7:
                    return "Jul";
                case 8:
                    return "Aut";
                case 9:
                    return "Sep";
                case 10:
                    return "Oct";
                case 11:
                    return "Nov";
                case 12:
                    return "Dec";
            }
        },
        calcScore(entry) {
            if (!entry.score) {
                return 'Results'
            }
            let entryScore = 0;
            let bonus_points = entry.score.bonus_points;
            let scoring_dimension = entry.score.scoring_dimension;
            let keys = Object.keys(scoring_dimension);
            for (let i = 0; i < keys.length; i++) {
                let score = scoring_dimension[keys[i]];
                entryScore = entryScore + parseInt(score);
            }
            let totalScore = entryScore + bonus_points;
            if (totalScore >= 75) {
                return 'Superior'
            } if (totalScore >= 65) {
                return 'Distinctive'
            }
            if (totalScore >= 50) {
                return 'Excellent'
            }
            return 'Results'
        }
    }
})
</script>
<style scoped lang="less">
.btn-cert {
    display: inline-block;
    text-align: center;
    background: #ff6450;
    border-radius: 5px;
    padding: 6px 10px;
    color: white;
    font-size: 15px;
    line-height: 24px;
}

.entry-item {
    background-color: white;
}

.header-wrapper {
    background: rgba(#ff6450, 0.15);
}

.table-header {
    width: 100%;
    word-wrap: break-word;
    padding: 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.project_code {
    flex: 2;
}

.recommend {
    flex: 1;
}

.entry_title {
    flex: 5;
}

.category {
    flex: 3;
}

.type {
    flex: 2;
}

.owner {
    flex: 3;
}

.lastUpdatedDate {
    text-align: center;
    flex: 3;
}

.status_results {
    text-align: center;
    flex: 3;
}
</style>
