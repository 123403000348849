<template>
    <div class="paymentOver" v-if="showPayment">
        <div class="paper" id="show" :style="myStyle">
            <img src="../assets/image/payBack.png" class="paybackImg">
            <div class="paytop">
                <div style="margin-right:10px;margin-top:50px;text-align:right;line-height: 20px;">
                    <div style="font-size:20px">Receipt</div>
                    <div>Extracurricular Activity Excellence International</div>
                    <div>Transaction ID:{{ item._id.$id }}</div>
                </div>
            </div>
            <div class="paystudentBlock" style="font-size:14px;line-height:24px;margin-top: 24px;">
                <div>You have successfully subscribed to the {{ item.project_info.project_name }}. </div>
                <div>Please find the details of your order and payment below.</div>
            </div>
            <div class="paystudentBlock">
                <div class="lineTitle">Student information：</div>
                <div class="txt"><span class="title">Student
                        Name:</span>{{ item.student_info ? (item.student_info.student_lastName_pinyin.charAt(0).toUpperCase() + item.student_info.student_lastName_pinyin.slice(1)) + ' ' + (item.student_info.student_givenName_pinyin.charAt(0).toUpperCase() + item.student_info.student_givenName_pinyin.slice(1)) : '' }}
                </div>
                <div class="txt"><span
                        class="title">Gender:</span>{{ item.student_info ? (item.student_info.student_gender === '女' ? 'Female' : 'Male') : '' }}
                </div>
                <div class="txt"><span
                        class="title">School:</span>{{ item.account_info ? item.account_info.account_nameEN : '' }}</div>
                <div class="txt"><span
                        class="title">Email:</span>{{ item.student_info ? item.student_info.student_email : '' }}</div>
                <div class="txt"><span
                        class="title">Mobile:</span>{{ item.student_info ? item.student_info.student_mobile : '' }}</div>
                <!-- <div class="txt"><span class="title">Guardian's
                        Email:</span>{{ item.student_info ? item.student_info.student_guardian_first_email : '' }}</div>
                <div class="txt"><span class="title">Guardian's
                        Mobile:</span>{{ item.student_info ? item.student_info.student_guardian_first_mobile : "" }}</div> -->
            </div>
            <div class="paystudentBlock">
                <div class="lineTitle">Order Information：</div>
                <div style="margin-top:10px;border:1px solid #606266;font-size: 14px;">
                    <div style="display:flex">
                        <div class="leftTable">Program </div>
                        <div class="rightTable">{{ item.project_info.project_name }}</div>
                    </div>
                    <div style="display:flex">
                        <div class="leftTable">Item</div>
                        <div class="rightTable">Subscription Fee</div>
                    </div>
                    <div style="display:flex">
                        <div class="leftTable">Payment Status</div>
                        <div class="rightTable">{{ item.app_info.application_status === '已缴费' ? 'Paid' : 'To be paid' }}</div>
                    </div>
                    <div style="display:flex">
                        <div class="leftTable">Payment Method</div>
                        <div class="rightTable">{{ item.app_info.application_payment_channel }}</div>
                    </div>
                    <div style="display:flex">
                        <div class="leftTable">Amount of Payment</div>
                        <div class="rightTable">{{ Number(item.payment_info.totalAmount) / 100 }}
                            {{ item.payment_info.currency }}</div>
                    </div>
                    <div style="display:flex">
                        <div class="leftTable">Amount of Refund</div>
                        <div class="rightTable">0</div>
                    </div>
                    <div style="display:flex">
                        <div style="padding:5px 10px;width:40%;border-right:1px solid #606266;">Amount Received</div>
                        <div style="padding:5px 10px;width:60%;text-align:right">
                            {{ Number(item.payment_info.totalAmount) / 100 }} {{ item.payment_info.currency }}</div>
                    </div>
                </div>
            </div>
            <div class="paystudentBlock">
                <h4>Payment Information:</h4>
                <!-- <div class="txt">
                    <span class="title">Payment Time:</span>{{item.payment_info.update_time}}
                </div> -->
                <!-- <div class="txt">
                    <span class="title">BrandName:</span>{{ item.payment_info.brandName }}
                </div> -->
                <div class="txt">
                    <span class="title">Payment ID:</span>{{ item._id.$id }}
                </div>
                <div class="txt">
                    <span class="title">Transaction ID:</span>{{ item.payment_info.transactionId }}
                </div>
            </div>
            <div class="paystudentBlock" style="font-size:14px;line-height:20px">
                <div>Please contact contact@eae.international regarding any questions about this transaction and payment.</div>
            </div>
        </div>
        <div class="foot" :style="footStyle">
            <div class="cancelBtn" @click="showPayment = false">Cancel</div>
            <div class="confirmBtn" @click="toDownload">Save as PDF</div>
        </div>
    </div>
</template>

<script>
import { getPaymentByApplication } from '../api/eae'
export default {
    data() {
        return {
            application_id: '',
            item: {},
            infoMap: {
                notify_time: '回调成功时间',
                notify_type: '回调类型',
                notify_id: '回调校验ID',
                charset: '编码格式',
                version: '调用接口版本',
                sign_type: '签名类型',
                sign: '签名',
                auth_app_id: '同appid',
                trade_no: '支付宝交易凭证号',
                app_id: '开发者的ID',
                out_trade_no: '商户订单号',
                out_biz_no: '商户业务号', /// 退款通知中的退款流水号
                buyer_id: '买家支付宝用户号', /// 以2088开头的纯16位数字
                seller_id: '卖家支付宝用户号',
                trade_status: '交易状态', // WAIT_BUYER_PAY
                total_amount: '订单金额',
                receipt_amount: '实收金额',
                invoice_amount: '开票金额',
                buyer_pay_amount: '用户在支付中交易的金额',
                point_amount: '集分宝金额',
                refund_fee: '总退款金额',
                subject: '订单标题',
                body: '商品描述',
                gmt_create: '交易创建时间',
                gmt_payment: '交易付款时间',
                gmt_refund: '交易退款时间',
                gmt_close: '交易结束时间',
                fund_bill_list: '支付金额信息',
                voucher_detail_list: '优惠券信息',
                passback_params: '回传参数'
            },
            showPayment: false,
            myStyle: {},
            footStyle: {}
        }
    },
    mounted() {
        this.computeHeight()
        window.onresize = () => {
            this.computeHeight()
        }
    },
    methods: {
        computeHeight() {
            const height = document.documentElement.clientHeight
            const newHeight = height - 100
            const rate = newHeight / 1123
            this.myStyle = {
                transform: `scale(${rate})`,
                left: `calc(50% - ${rate * 104}mm)`
            }
            this.footStyle = {
                top: `${height - 60}px`
            }
        },
        getPaymentByApplication() {
            getPaymentByApplication(this.application_id).then(res => {
                this.item = res.data
            })
        },
        open(application_id) {
            this.application_id = application_id
            this.getPaymentByApplication()
            this.showPayment = true
        },
        toDownload() {
            if (!this.item) {
                this.$message.error('Fail')
                return
            }
            const router = this.$router.resolve({
                path: '/receipt',
                query: {
                    application_id: this.application_id
                }
            })
            window.open(router.href, '_blank')
        }
    }
}
</script>

<style lang="scss">
.paymentOver {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);

    .foot {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;

        .cancelBtn {
            background: #fff;
            color: #0E4890;
            font-family: "DDINAlternateRegular";
            cursor: pointer;
            font-size: 18px;
            border: 2px solid #0E4890;
            border-radius: 6px;
            width: 45%;
            max-width: 200px;
            height: 40px;
            font-weight: bold;
            text-align: center;
            line-height: 40px;
        }

        .confirmBtn {
            background: #0E4890;
            border: 2px solid #0E4890;
            color: #fff;
            font-family: "DDINAlternateRegular";
            cursor: pointer;
            border-radius: 6px;
            font-size: 18px;
            width: 45%;
            max-width: 200px;
            height: 40px;
            font-weight: bold;
            text-align: center;
            line-height: 40px;
            margin-left: 10px;
        }
    }
}

.paybackImg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.paystudentBlock {
    margin: 36px auto;
    padding: 0 16px;
    color: #262727;

    .lineTitle {
        font-size: 18px;
        font-family: "DDINAlternateRegular";
        font-weight: normal;
        color: #262727;
        font-weight: bold;
        margin-bottom: 12px;
    }

    .title {
        font-weight: bold;
        margin-right: 10px;
    }

    .leftTable {
        padding: 5px 10px;
        width: 40%;
        border-right: 1px solid #606266;
        border-bottom: 1px solid #606266;
    }

    .rightTable {
        padding: 5px 10px;
        width: 60%;
        text-align: right;
        border-bottom: 1px solid #606266;
    }

    .txt {
        margin-top: 10px;
        font-size: 14px;
    }
}

.paytop {
    width: 190mm;
    height: 12%;
    display: flex;
    justify-content: flex-end;
}

.paper {
    width: 210mm;
    height: 277.14mm;
    padding: 10mm;
    overflow: hidden;
    position: relative;
    transform-origin: 0 0;
    top: 50px;
}
</style>
