import { render, staticRenderFns } from "./wao.vue?vue&type=template&id=7845a832&scoped=true"
import script from "./wao.vue?vue&type=script&lang=js"
export * from "./wao.vue?vue&type=script&lang=js"
import style0 from "./wao.vue?vue&type=style&index=0&id=7845a832&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7845a832",
  null
  
)

export default component.exports