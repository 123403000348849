<template>
  <div class="activity-container" :style="{ backgroundColor: app_status == 'Active' ? '#ffffff' : '#E7E6E6' }">
    <div class="p-6 my-4 flex items-center justify-between">
      <div class="flex">
        <div class="activity-info mr-6">
          <div style="display: flex;">
            <p :style="formatAppStatusStyle()">{{ app_status }}</p>
          </div>
          <p class="activity-name text-base font-bold mb-4">
            {{ info.project_info.project_name }}
          </p>
          <div style="display: flex;color: #333;" v-if="app_status === 'Active' || app_status == 'Expired'">
            <p style="font-weight: bold;">Effective Date:&nbsp;</p>
            <p>{{ effective_date }}</p>
            <p>&nbsp; | &nbsp;</p>
            <p style="font-weight: bold;">Expiry Date:&nbsp;</p>
            <p>{{ expiry_date }}</p>
          </div>
          <p v-else style="color: #333;font-size: 14px;line-height: 18px;font-weight: bold;">
            Please proceed with the payment to activate your subscription status. The subscription is valid from the
            date of payment until the day before the same calendar date next year.
          </p>
        </div>
      </div>
      <div class="flex items-center">
        <div v-if="app_status == 'Active' || app_status == 'Expired'">
          <p class="btn-receipt" v-if="info.application_status != '无需缴费'" @click="handleReceipt()">Receipt</p>
        </div>
        <div v-else>
          <p class="btn-normal" v-if="info.application_status == '待缴费'" style="margin-bottom: 10px;"
            @click="handleRegister()">Pay</p>
          <!-- <p class="btn-cancel" @click="handleCancel()">Cancel</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Activity",
  props: {
    info: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      app_status: '',
      effective_date: '',
      expiry_date: ''
    }
  },
  watch: {
    info: {
      handler(newVal, oldVal) {
        this.handleApplicationInfo()
      },
      immediate: true
    }
  },
  methods: {

    formatRegistrationTime(time) {
      var date = new Date(time);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      return this.formatMonthToEng(month) + " " + day + ", " + year;
    },

    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
    },

    formatAppStatusStyle() {
      let style = {
        fontSize: '16px'
      }
      if (this.app_status == 'Active') {
        style.backgroundColor = '#FFD9D4'
        style.color = '#333333'
      } else if (this.app_status == 'Expired') {
        style.backgroundColor = '#808080'
        style.color = '#ffffff'
      } else {
        style.backgroundColor = '#FFF2CC'
        style.color = '#333333'
      }
      return style
    },


    handleApplicationInfo() {
      let membershipExpiredTime = -1
      if (this.info.application_payment_date && this.info.application_payment_date != '') {//已缴费
        let date = new Date(this.info.application_payment_date + " 00:00:00")
        this.effective_date = this.formatRegistrationTime(date.getTime())
        date.setFullYear(date.getFullYear() + 1)
        var nextYearTimestamp = date.getTime() - 24 * 60 * 60;
        this.expiry_date = this.formatRegistrationTime(nextYearTimestamp)
        if (membershipExpiredTime < nextYearTimestamp) {
          membershipExpiredTime = nextYearTimestamp
        }
      } else if (this.info.application_status == '无需缴费') {
        let date = new Date(this.info.create_time * 1000)
        this.effective_date = this.formatRegistrationTime(date.getTime())
        date.setFullYear(date.getFullYear() + 1)
        var nextYearTimestamp = date.getTime() - 24 * 60 * 60;;
        this.expiry_date = this.formatRegistrationTime(nextYearTimestamp)
        if (membershipExpiredTime < nextYearTimestamp) {
          membershipExpiredTime = nextYearTimestamp
        }
      }
      let currentTime = new Date().getTime()
      if (membershipExpiredTime < currentTime || membershipExpiredTime == -1) {
        this.app_status = 'Expired'
        if (this.info.current_application == 'YES') {
          this.app_status = 'Pending Payment'
        }
      } else {
        this.app_status = 'Active'
      }
      // this.app_status = 'Pending Payment'
      // this.app_status = 'Active'
    },

    handleCancel() {
      this.$emit("cancel");
    },

    handleRegister() {
      this.$emit("register");
    },
    handleReceipt() {
      this.$emit("receipt");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-normal {
  cursor: pointer;
  width: 90px;
  text-align: center;
  background-color: var(--c-primary);
  border-radius: 5px;
  padding: 12px 0px;
  box-sizing: border-box;
  color: white;
  font-size: 15px;
}

.btn-cancel {
  cursor: pointer;
  width: 90px;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--c-primary);
  border-radius: 5px;
  padding: 12px 0px;
  box-sizing: border-box;
  color: var(--c-primary);
  font-size: 15px;
}


.btn-receipt {
  width: 90px;
  text-align: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px var(--c-primary) solid;
  padding: 12px 0px;
  box-sizing: border-box;
  color: var(--c-primary);
  font-size: 15px;
  cursor: pointer;
}

.activity-container {
  position: relative;

  .status {
    font-size: 15px;
    font-weight: bold;
    color: #ff8e3b;
    line-height: 22px;
    margin-right: 60px;
    user-select: none;

    &.done {
      color: #2fd098;
    }
  }

  .activity-info {
    .activity-name {
      margin-top: 5px;
      color: var(--c-primary);
      font-size: 22px;
    }
  }
}

.paymentBtn {
  padding: 5px 10px;
  background: #FF4040;
  color: #fff;
  font-size: 18px;
  border-radius: 20px;
  cursor: pointer;
  margin-left: 14px;
  font-family: "DDINAlternateRegular";
}
</style>
