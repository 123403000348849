import Vue from "vue";
import VueRouter from "vue-router";
import { getUserId } from "../utils/store";

Vue.use(VueRouter);

const userAgentInfo = navigator.userAgent;
const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
const getArr = Agents.filter((i) => userAgentInfo.includes(i));
const isMobile = !!getArr.length;

let routes;

const commonRoutes = [{
    path: "/home",
    redirect: "/home/activities",
    component: () =>
        import("../views/Home"),
    meta: {
        requiresAuth: true,
        title: "首页",
    },
    children: [{
        path: "tutorialVideo",
        name: "tutorialVideo",
        component: () =>
            import("../views/TutorialVideo"),
        meta: {
            requiresAuth: true,
            title: "TutorialVideo",
        },
    },
    {
        path: "student-tier",
        name: "Student Tier",
        component: () =>
            import("../views/StudentTier/index.vue"),
        meta: {
            requiresAuth: false,
            title: "Student Tier",
        },
    },
    {
        path: "news&updates",
        name: "News&Updates",
        component: () =>
            import("../views/NewsUpdates/index.vue"),
        meta: {
            requiresAuth: true,
            title: "News&Updates",
        },
    },
    {
        path: "schoolRegistrations",
        name: "SchoolRegistrations",
        component: () =>
            import("../views/SchoolAdmin/registrations.vue"),
        meta: {
            requiresAuth: true,
            title: "SchoolRegistrations",
        },
    },
    {
        path: "studentRegistrations",
        name: "StudentRegistrations",
        component: () =>
            import("../views/SchoolAdmin/studentRegistrations.vue"),
        meta: {
            requiresAuth: true,
            title: "StudentRegistrations",
        },
    },
    {
        path: "studentResults",
        name: "studentResults",
        component: () =>
            import("../views/ViewAccountStudentResults/index.vue"),
        meta: {
            requiresAuth: true,
            title: "studentResults",
        },
    },
    {
        path: "official_course",
        name: "Official Course",
        component: () =>
            import("../views/OfficialCourse/index.vue"),
        meta: {
            requiresAuth: true,
            title: "Official Course",
        },
    },
    {
        path: "official_course/order",
        name: "Official Course Order",
        component: () =>
            import("../views/OfficialCourse/order.vue"),
        meta: {
            requiresAuth: true,
            title: "Official Course Order",
        },
    },
    {
        path: "manageStudentList",
        name: "ManageStudentList",
        component: () =>
            import("../views/SchoolAdmin/manageStudentList.vue"),
        meta: {
            requiresAuth: true,
            title: "ManageStudentList",
        },
    },
    {
        path: "schoolPrograms",
        name: "SchoolPrograms",
        component: () =>
            import("../views/SchoolAdmin/programs.vue"),
        meta: {
            requiresAuth: true,
            title: "SchoolPrograms",
        },
    },
    {
        path: "studentPrograms",
        name: "StudentPrograms",
        component: () =>
            import("../views/SchoolAdmin/studentPrograms.vue"),
        meta: {
            requiresAuth: true,
            title: "SchoolPrograms",
        },
    },
    {
        path: "programsDetail",
        name: "programsDetail",
        component: () =>
            import("../views/SchoolAdmin/programsDetail.vue"),
        meta: {
            requiresAuth: true,
            title: "Services Programs",
        },
    },
    {
        path: "programs",
        name: "Programs",
        component: () =>
            import("../views/Programs/index.vue"),
        meta: {
            requiresAuth: true,
            title: "My Programs",
        },
    },
    {
        path: "resource-center/wao",
        name: "ResourceCenter WAO",
        component: () =>
            import("../views/ResourceCenter/WAO/home.vue"),
        meta: {
            requiresAuth: true,
            title: "ResourceCenter WAO",
        },
    },
    {
        path: "exam-center/wao",
        name: "ExamCenter WAO",
        component: () =>
            import("../views/ExamCenter/WAO/index.vue"),
        meta: {
            requiresAuth: true,
            title: "ExamCenter WAO",
        },
    },
    {
        path: "passes",
        name: "Passes",
        component: () =>
            import("../views/REPass/list.vue"),
        meta: {
            requiresAuth: true,
            title: "Passes",
        },
    },
    {
        path: "pass/detail",
        name: "Pass",
        component: () =>
            import("../views/REPass/detail.vue"),
        meta: {
            requiresAuth: true,
            title: "Pass",
        },
    },
    {
        path: "activities",
        name: "activities",
        component: () =>
            import("../views/Activities"),
        meta: {
            requiresAuth: true,
            title: "Activities",
        },
    },
    {
        path: "activityEdit",
        name: "activityEdit",
        component: () =>
            import("../views/ActivityEdit"),
        meta: {
            requiresAuth: true,
            title: "ActivityEdit",
        },
    },
    {
        path: "activityOptions",
        name: "activityOptions",
        component: () =>
            import("../views/ActivityOptions"),
        meta: {
            requiresAuth: true,
            title: "ActivityOptions",
        },
    },
    {
        path: "activityOptionsView",
        name: "activityOptionsView",
        component: () =>
            import("../views/ActivityOptionsView"),
        meta: {
            requiresAuth: true,
            title: "activityOptionsView",
        },
    },
    {
        path: "activityAdvices",
        name: "activityAdvices",
        component: () =>
            import("../views/ActivityAdvices"),
        meta: {
            requiresAuth: true,
            title: "ActivityAdvices",
        },
    },
    {
        path: "reviewEntries",
        name: "reviewEntries",
        component: () =>
            import("../views/ReviewEntries"),
        meta: {
            requiresAuth: true,
            title: "ReviewEntries",
        },
    },
    {
        path: "entryEdit",
        name: "entryEdit",
        component: () =>
            import("../views/EntryEdit"),
        meta: {
            requiresAuth: true,
            title: "EntryEdit",
        },
    },
    {
        path: "standards",
        name: "standards",
        component: () =>
            import("../views/Standards"),
        meta: {
            requiresAuth: true,
            title: "Standards",
        },
    },
    {
        path: "registration",
        name: "registration",
        component: () =>
            import("../views/Registration"),
        meta: {
            requiresAuth: true,
            title: "Registration",
        },
    },
    {
        path: "universal-pass",
        name: "universal-pass",
        component: () =>
            import("../views/UniversalPass/index.vue"),
        meta: {
            requiresAuth: true,
            title: "UniversalPass",
        },
    },
    {
        path: "select-product",
        name: "SelectProduct",
        component: () =>
            import("../views/UniversalPass/selectProduct.vue"),
        meta: {
            requiresAuth: true,
            title: "SelectProduct",
        },
    },
    {
        path: "verify-pass",
        name: "Verify Pass",
        component: () =>
            import("../views/Programs/selectPass.vue"),
        meta: {
            requiresAuth: true,
            title: "Verify Pass",
        },
    },
    {
        path: "up-order",
        name: "Subscription Payment",
        component: () =>
            import("../views/UniversalPass/order.vue"),
        meta: {
            requiresAuth: true,
            title: "Subscription Payment",
        },
    },
    {
        path: "pass-order",
        name: "REPass Payment",
        component: () =>
            import("../views/REPass/order.vue"),
        meta: {
            requiresAuth: true,
            title: "REPass Payment",
        },
    },
    {
        path: "store",
        name: "EAE Store",
        component: () =>
            import("../views/Store/list.vue"),
        meta: {
            requiresAuth: true,
            title: "EAE Store",
        },
    },
    {
        path: "store/project",
        name: "EAE Store Project",
        component: () =>
            import("../views/Store/projectDetail.vue"),
        meta: {
            requiresAuth: true,
            title: "EAE Store Project",
        },
    },
    {
        path: "program-order",
        name: "Program Payment",
        component: () =>
            import("../views/Programs/order.vue"),
        meta: {
            requiresAuth: true,
            title: "Program Payment",
        },
    },
    {
        path: "registrationEdit",
        name: "registrationEdit",
        component: () =>
            import("../views/RegistrationEdit"),
        meta: {
            requiresAuth: true,
            title: "RegistrationEdit",
        },
    },
    {
        path: "payment",
        name: "payment",
        component: () =>
            import("../views/Payment"),
        meta: {
            requiresAuth: true,
            title: "Payment",
        },
    },
    {
        path: "activityCategory",
        name: "activityCategory",
        component: () =>
            import("../views/ActivityCategory"),
        meta: {
            requiresAuth: true,
            title: "ActivityCategory",
        },
    },
    {
        path: "myProfile",
        name: "myProfile",
        component: () =>
            import("../views/Profile"),
        meta: {
            requiresAuth: true,
            title: "Profile",
        },
    },
    {
        path: "messages",
        name: "Message",
        component: () =>
            import("../views/Message"),
        meta: {
            requiresAuth: true,
            title: "Message",
        },
    },
    {
        path: "adminform",
        name: "Adminform",
        component: () =>
            import("../views/Admin/adminform"),
        meta: {
            requiresAuth: true,
            title: "Adminform",
        },
    },
    {
        path: "admin-enties",
        name: "EntryList",
        component: () =>
            import("../views/Admin/EntryList"),
        meta: {
            requiresAuth: true,
            title: "EntryList",
        },
    },
    {
        path: "accountapps",
        name: "AccountApplications",
        component: () =>
            import("../views/Advisor/accountapps"),
        meta: {
            requiresAuth: true,
            title: "AccountApplications",
        },
    },
    {
        path: "studentList",
        name: "StudentList",
        component: () =>
            import("../views/Advisor/studentList"),
        meta: {
            requiresAuth: true,
            title: "StudentList",
        },
    },
    {
        path: "studentDetail",
        name: "StudentDetail",
        component: () =>
            import("../views/Advisor/studentDetail"),
        meta: {
            requiresAuth: true,
            title: "StudentDetail",
        },
    },
    {
        path: "score",
        name: "Score",
        component: () =>
            import("../views/Score/index"),
        meta: {
            requiresAuth: true,
            title: "Score",
        },
    },
    {
        path: "categoryDetail",
        name: "CategoryDetail",
        component: () =>
            import("../views/CategoryDetail"),
    },
    {
        path: "newCategoryList",
        name: "newCategoryList",
        component: () =>
            import("../views/AdvisorCategory"),
    },
    {
        path: "advisorStudentList",
        name: "advisorStudentList",
        component: () =>
            import("../views/AdvisorStudentList"),
    },
    {
        path: "advisorCertificateList",
        name: "advisorCertificateList",
        component: () =>
            import("../views/AdvisorCertificateList/index.vue"),
    },
    {
        path: "advisorCertificate",
        name: "advisorCertificate",
        component: () =>
            import("../views/AdvisorCertificateList/certificate.vue"),
    },
    {
        path: "projectList",
        name: "Projectlist",
        component: () =>
            import("../views/ProjectList/index.vue"),
        meta: {
            requiresAuth: true,
            title: "Projectlist",
        },
    },
    {
        path: "projectList",
        name: "Projectlist",
        component: () =>
            import("../views/ProjectList/index.vue"),
        meta: {
            requiresAuth: true,
            title: "Projectlist",
        },
    },
    {
        path: "projectStudents",
        name: "projectStudents",
        component: () =>
            import("../views/ProjectList/StudentList.vue"),
        meta: {
            requiresAuth: true,
            title: "Projectlist",
        },
    },
    {
        path: "advisorEntry",
        name: "advisorEntry",
        component: () =>
            import("../views/AdvisorEntry"),
    },
    {
        path: "advisorStudentEntryList",
        name: "advisorStudentEntryList",
        component: () =>
            import("../views/AdvisorStudentEntryList"),
        meta: {
            requiresAuth: true,
            title: "Entry List",
        },
    },
    {
        path: '/receipt',
        name: 'receipt',
        component: () =>
            import('../views/receiptT.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'teacherManagement',
        name: 'TeacherManagement',
        component: () =>
            import('../views/TeacherManagement'),
        meta: {
            requiresAuth: true,
            title: 'Teacher Management'
        }
    },
    {
        path: 'myPasses',
        name: 'MyPasses',
        component: () =>
            import('../views/MyPasses'),
        meta: {
            requiresAuth: true,
            title: 'My Passes'
        }
    },
    ],
},];

if (isMobile) {
    routes = [{
        path: "/login",
        name: "Login",
        component: () =>
            import("../views/login/index.vue"),
        meta: {
            requiresAuth: false,
            title: "登录",
        },
    },
    {
        path: "/",
        redirect: "/home",
        meta: {
            requiresAuth: true,
            title: "首页",
        },
    },
    {
        path: "/appform",
        name: "appform",
        component: () =>
            import("../views/Appform/mobileform.vue"),
        meta: {
            requiresAuth: false,
            title: "Appform",
        },
    },
    {
        path: "/contactappform",
        name: "contactappform",
        component: () =>
            import("../views/ContactAppform/mobileform.vue"),
        meta: {
            requiresAuth: false,
            title: "ContactAppform",
        },
    },

    {
        path: "/registrationsuccess",
        name: "registrationsuccess",
        component: () =>
            import("../views/RegistrationSuccess/success.vue"),
        meta: {
            requiresAuth: false,
            title: "Registration Success",
        },
    },

    {
        path: "/accountform",
        name: "accountform",
        component: () =>
            import("../views/Accountform/mobile.vue"),
        meta: {
            requiresAuth: false,
            title: "Accountform",
        },
    },
    {
        path: "/studentrequest",
        name: "studentrequest",
        component: () =>
            import("../views/Nominatorform/mobile.vue"),
        meta: {
            requiresAuth: false,
            title: "Studentrequest",
        },
    },
    {
        path: "/qrcode",
        name: "qrcode",
        component: () =>
            import("../views/qrcode.vue"),
        meta: {
            requiresAuth: false,
            title: "qrcode",
        },
    },
    {
        path: "/form/aeo",
        name: "AEO  Student Form",
        component: () =>
            import("../views/Form/AEOStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "AEO  Student Form",
        },
    },
    {
        path: "/form/bpa",
        name: "BPA  Student Form",
        component: () =>
            import("../views/Form/BPAStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "BPA  Student Form",
        },
    },
    {
        path: "/form/ifo",
        name: "IFO  Student Form",
        component: () =>
            import("../views/Form/IFOStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "IFO  Student Form",
        },
    },
    {
        path: "/form/envirothon",
        name: "Envirothon Form",
        component: () =>
            import("../views/Form/EnvirothonAsia.vue"),
        meta: {
            requiresAuth: false,
            title: "IFO  EnvirothonAsia Form",
        },
    },
    {
        path: "/form/ipsyo",
        name: "IPSYO  Student Form",
        component: () =>
            import("../views/Form/IPsyO.vue"),
        meta: {
            requiresAuth: false,
            title: "IPSYO  Student Form",
        },
    },
    {
        path: "/form/ienvo",
        name: "IEnvo  Student Form",
        component: () =>
            import("../views/Form/IEnvOStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "IEnvo  Student Form",
        },
    },
    {
        path: "/form/ibo",
        name: "IBO  Student Form",
        component: () =>
            import("../views/Form/IBOStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "IBO  Student Form",
        },
    },
    {
        path: "/form/wao",
        name: "WAO  Student Form",
        component: () =>
            import("../views/Form/WAOStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "WAO  Student Form",
        },
    },
    {
        path: "/form/success",
        name: "Form Success",
        component: () =>
            import("../views/Form/submitFormSuccess.vue"),
        meta: {
            requiresAuth: false,
            title: "Form Success",
        },
    },
    {
        path: "/form/registration",
        name: "Student Registration",
        component: () =>
            import("../views/Form/CommonStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "Student Registration",
        },
    },
    {
        path: "/form/eae",
        name: "EAE  Student Form",
        component: () =>
            import("../views/Form/EAEStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "EAE  Student Form",
        },
    },
    {
        path: "/form/wec",
        name: "WEC  Student Form",
        component: () =>
            import("../views/Form/WECStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "WEC  Student Form",
        },
    },
    {
        path: "/form/nim",
        name: "NIM  Student Form",
        component: () =>
            import("../views/Form/NIMStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "NIM  Student Form",
        },
    },

    ...commonRoutes,
    ];
} else {
    routes = [{
        path: "/login",
        name: "Login",
        component: () =>
            import("../views/login/index.vue"),
        meta: {
            requiresAuth: false,
            title: "登录",
        },
    },
    {
        path: "/",
        redirect: "/home",
        meta: {
            requiresAuth: true,
            title: "首页",
        },
    },
    {
        path: "/appform",
        name: "appform",
        component: () =>
            import("../views/Appform/pcform.vue"),
        meta: {
            requiresAuth: false,
            title: "Appform",
        },
    },

    {
        path: "/contactappform",
        name: "contactappform",
        component: () =>
            import("../views/ContactAppform/pcform.vue"),
        meta: {
            requiresAuth: false,
            title: "Contactappform",
        },
    },

    {
        path: "/accountform",
        name: "accountform",
        component: () =>
            import("../views/Accountform/pc.vue"),
        meta: {
            requiresAuth: false,
            title: "Accountform",
        },
    },
    {
        path: "/studentrequest",
        name: "studentrequest",
        component: () =>
            import("../views/Nominatorform/pc.vue"),
        meta: {
            requiresAuth: false,
            title: "Studentrequest",
        },
    },
    {
        path: "/qrcode",
        name: "qrcode",
        component: () =>
            import("../views/qrcode.vue"),
        meta: {
            requiresAuth: false,
            title: "qrcode",
        },
    },
    {
        path: "/certificate",
        name: "Cert",
        component: () =>
            import("../views/cert/index"),
        meta: {
            requiresAuth: true,
            title: "EAECertificate",
        },
    },
    {
        path: "/up-pay",
        name: "Subscription Payment",
        component: () =>
            import("../views/UniversalPass/pay.vue"),
        meta: {
            requiresAuth: true,
            title: "Subscription Payment",
        },
    },
    {
        path: "/pass-pay",
        name: "REPass Payment",
        component: () =>
            import("../views/REPass/pay.vue"),
        meta: {
            requiresAuth: true,
            title: "REPass Payment",
        },
    },
    {
        path: "/form/aeo",
        name: "AEO  Student Form",
        component: () =>
            import("../views/Form/AEOStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "AEO  Student Form",
        },
    },
    {
        path: "/form/bpa",
        name: "BPA  Student Form",
        component: () =>
            import("../views/Form/BPAStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "BPA  Student Form",
        },
    },
    {
        path: "/form/ifo",
        name: "IFO  Student Form",
        component: () =>
            import("../views/Form/IFOStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "IFO  Student Form",
        },
    },
    {
        path: "/form/envirothon",
        name: "Envirothon Form",
        component: () =>
            import("../views/Form/EnvirothonAsia.vue"),
        meta: {
            requiresAuth: false,
            title: "IFO  EnvirothonAsia Form",
        },
    },
    {
        path: "/form/ipsyo",
        name: "IPSYO  Student Form",
        component: () =>
            import("../views/Form/IPsyO.vue"),
        meta: {
            requiresAuth: false,
            title: "IPSYO  Student Form",
        },
    },
    {
        path: "/form/ienvo",
        name: "IEnvo  Student Form",
        component: () =>
            import("../views/Form/IEnvOStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "IEnvo  Student Form",
        },
    },
    {
        path: "/form/ibo",
        name: "IBO  Student Form",
        component: () =>
            import("../views/Form/IBOStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "IBO  Student Form",
        },
    },
    {
        path: "/form/wao",
        name: "WAO  Student Form",
        component: () =>
            import("../views/Form/WAOStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "WAO  Student Form",
        },
    },
    {
        path: "/form/success",
        name: "Form Success",
        component: () =>
            import("../views/Form/submitFormSuccess.vue"),
        meta: {
            requiresAuth: false,
            title: "Form Success",
        },
    },
    {
        path: "/program-pay",
        name: "Program Payment",
        component: () =>
            import("../views/Programs/pay.vue"),
        meta: {
            requiresAuth: true,
            title: "Program Payment",
        },
    },
    {
        path: "/test",
        name: "Test",
        component: () =>
            import("../views/test.vue"),
        meta: {
            requiresAuth: true,
            title: "Test",
        },
    },
    {
        path: "/newScore",
        name: "index",
        component: () =>
            import("../views/NewScore/index.vue"),
        meta: {
            requiresAuth: true,
            title: "EAEScore",
        },
    },
    {
        path: "/form/registration",
        name: "Student Registration",
        component: () =>
            import("../views/Form/CommonStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "Student Registration",
        },
    },
    {
        path: "/form/eae",
        name: "EAE  Student Form",
        component: () =>
            import("../views/Form/EAEStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "EAE  Student Form",
        },
    },
    {
        path: "/form/wec",
        name: "WEC  Student Form",
        component: () =>
            import("../views/Form/WECStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "WEC  Student Form",
        },
    },
    {
        path: "/form/nim",
        name: "NIM  Student Form",
        component: () =>
            import("../views/Form/NIMStudentForm.vue"),
        meta: {
            requiresAuth: false,
            title: "NIM  Student Form",
        },
    },
    {
        path: "/accountForm/nim",
        name: "NIM  Account Form",
        component: () =>
            import("../views/Accountform/nim.vue"),
        meta: {
            requiresAuth: false,
            title: "NIM  Account Form",
        },
    },
    {
        path: "/accountForm/wec",
        name: "WEC  Account Form",
        component: () =>
            import("../views/Accountform/wec.vue"),
        meta: {
            requiresAuth: false,
            title: "WEC  Account Form",
        },
    },
    {
        path: "/accountForm/bpa",
        name: "BPA  Account Form",
        component: () =>
            import("../views/Accountform/bpa.vue"),
        meta: {
            requiresAuth: false,
            title: "BPA  Account Form",
        },
    },
    {
        path: "/accountForm/envirothon",
        name: "Envirothon  Account Form",
        component: () =>
            import("../views/Accountform/envirothon.vue"),
        meta: {
            requiresAuth: false,
            title: "Envirothon  Account Form",
        },
    },
    {
        path: "/accountForm/ipsyo",
        name: "IPsyO Account Form",
        component: () =>
            import("../views/Accountform/ipsyo.vue"),
        meta: {
            requiresAuth: false,
            title: "IPsyO Account Form",
        },
    },
    {
        path: "/accountForm/ibo",
        name: "IBO Account Form",
        component: () =>
            import("../views/Accountform/ibo.vue"),
        meta: {
            requiresAuth: false,
            title: "IBO Account Form",
        },
    },
    {
        path: "/accountForm/ifo",
        name: "IFO Account Form",
        component: () =>
            import("../views/Accountform/ifo.vue"),
        meta: {
            requiresAuth: false,
            title: "IFO Account Form",
        },
    },
    {
        path: "/accountForm/wao",
        name: "WAO Account Form",
        component: () =>
            import("../views/Accountform/wao.vue"),
        meta: {
            requiresAuth: false,
            title: "WAO Account Form",
        },
    },
    {
        path: "/accountForm/ienvo",
        name: "IEnvO Account Form",
        component: () =>
            import("../views/Accountform/ienvo.vue"),
        meta: {
            requiresAuth: false,
            title: "IEnvO Account Form",
        },
    },
    {
        path: "/course_content",
        name: "Course Content",
        component: () =>
            import("../views/OfficialCourse/courseHome.vue"),
        meta: {
            requiresAuth: true,
            title: "Course Content",
        },
    },
    {
        path: "/collection-list",
        name: "Collection List",
        component: () =>
            import("../views/OfficialCourse/CollectionList.vue"),
        meta: {
            requiresAuth: true,
            title: "Collection List",
        },
    },
    {
        path: "/chatai",
        name: "chatai",
        component: () =>
            import("../views/OfficialCourse/AIQuestion.vue"),
        meta: {
            requiresAuth: true,
            title: "chatai",
        },
    },
    {
        path: "/award",
        name: "Student Award",
        component: () =>
            import("../views/Award/index.vue"),
        meta: {
            requiresAuth: true,
            title: "Student Award",
        },
    },
    {
        path: "/official_course-pay",
        name: "Official Course Payment",
        component: () =>
            import("../views/OfficialCourse/pay.vue"),
        meta: {
            requiresAuth: true,
            title: "Official Course Payment",
        },
    },
    {
        path: "/wao/exam-ready",
        name: "WAO Exam Ready",
        component: () =>
            import("../views/ExamCenter/WAO/exam_ready.vue"),
        meta: {
            requiresAuth: true,
            title: "WAO Exam Ready",
        },
    },
    {
        path: "/wao/lecture-ready",
        name: "WAO Lecture Ready",
        component: () =>
            import("../views/ExamCenter/WAO/exam_ready.vue"),
        meta: {
            requiresAuth: true,
            title: "WAO Lecture Ready",
        },
    },
    {
        path: "/wao/videoPlay",
        name: "WAO VideoPlay",
        component: () =>
            import("../views/ExamCenter/WAO/videoPlay.vue"),
        meta: {
            requiresAuth: true,
            title: "WAO VideoPlay",
        },
    },
    {
        path: "/wao/exam",
        name: "WAO Exam",
        component: () =>
            import("../views/ExamCenter/WAO/exam.vue"),
        meta: {
            requiresAuth: true,
            title: "WAO Exam",
        },
    },
    {
        path: "/wao/studentscreen",
        name: "WAO studentscreen",
        component: () =>
            import("../views/ExamCenter/WAO/screen.vue"),
        meta: {
            requiresAuth: true,
            title: "WAO studentscreen",
        },
    },
    {
        path: "/wao/award",
        name: "WAO Award",
        component: () =>
            import("../views/ExamCenter/WAO/award.vue"),
        meta: {
            requiresAuth: true,
            title: "WAO Award",
        },
    },
    ...commonRoutes,
    ];
}

const router = new VueRouter({
    linkActiveClass: "active",
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.path === "/login") {
        next();
    } else {
        var userId = getUserId();
        if (to.meta.requiresAuth && (userId == undefined || userId == "")) {
            next({
                path: "/login",
            });
        } else {
            next();
        }
    }
});
export default router;