<template>
    <div style="width: 100%; height: 100%; padding: 20px; box-sizing: border-box">
        <div class="score-container pdf-screen" ref="scoreContent" id="print">
            <img src="../../assets/image/transcript_bg.jpg" crossorigin="anonymous" class="transcript-bg"
                style="z-index: -1;" />
            <div style="width: 100%;padding: 0px 30px;box-sizing: border-box;">
                <div style="display: flex;align-items: center;margin-top: 60px;">
                    <img src="../../assets/image/score_logo.png" style="width: 300px;height: 92px;object-fit: cover;">
                    <div style="flex: 1;width: 100%;padding-left: 15px;text-align: center;">
                        <p class="project_code">{{ showProjectName }} Extracurricular Activity Excellence </p>
                        <p class="content-label" style="margin-top: 5px;font-size: 28px;">Transcript of Entry</p>
                    </div>
                </div>
                <p class="score-date">Date: {{ score_date }}</p>
                <div class="empty-line"></div>
                <p class="content-label" style="margin-top: 10px;">Entry Information</p>
                <div class="empty-line"></div>
                <div class="entry-content">
                    <div style="display: flex;margin-top: 15px;">
                        <div style="flex: 5;display: flex;">
                            <p style="width: 100px;text-align: right;font-weight: bold;">Entry Title:&nbsp;</p>
                            <p>{{ entryInfo.title }}</p>
                        </div>
                        <div style="flex: 3;display: flex;">
                            <p style="width: 80px;text-align: right;font-weight: bold;"> Student:&nbsp;</p>
                            <p> {{ studentName }}</p>
                        </div>
                    </div>
                    <div style="display: flex;margin-top: 20px;">
                        <div style="flex: 5;display: flex;">
                            <p style="width: 100px;text-align: right;font-weight: bold;">Category:&nbsp;</p>
                            <p>{{ entryInfo.category.name }}</p>
                        </div>
                        <div style="flex: 3;display: flex;">
                            <p style="width: 80px;text-align: right;font-weight: bold;"> School:&nbsp;</p>
                            <p style="line-height: 24px;"> {{ entryInfo.account.account_nameEN }}</p>

                        </div>
                    </div>
                    <div style="display: flex;margin-top: 20px;">
                        <div style="flex: 1;display: flex;">
                            <p style="width: 100px;text-align: right;font-weight: bold;">Type:&nbsp;</p>
                            <p> {{ entryType }}</p>
                        </div>
                    </div>
                </div>

                <div class="empty-line" style="margin-top: 20px;"></div>
                <p class="content-label" style="margin-top: 10px;">Score</p>
                <div class="empty-line"></div>
                <div style="display: flex;height: 450px;">
                    <div id="echart-radar" class="echart_size"></div>
                    <div style="flex: 1;padding: 40px 0px 20px 0px;width: 100%;box-sizing: border-box;font-size: 14px;">
                        <div style="display: flex;">
                            <p class="table-normal-header" style="flex: 4;font-weight: bold;">Criteria</p>
                            <div style="width: 4px;"></div>
                            <p class="table-normal-header" style="flex: 1;font-weight: bold;"> Max.</p>
                            <div style="width: 4px;"></div>
                            <p class="table-normal-header" style="flex: 1;font-weight: bold;">Score</p>
                        </div>
                        <div v-for="(dimension, index)  in dimensions" :key="index"
                            style="display: flex;margin-top: 4px;">
                            <p class="table-normal" style="flex: 4;">{{ dimension.name }}</p>
                            <div style="width: 4px;"></div>
                            <p class="table-normal" style="flex: 1;">{{ dimension.value }}</p>
                            <div style="width: 4px;"></div>
                            <p class="table-normal" style="flex: 1;">{{ dimension.real_score }}</p>
                        </div>
                        <div style="display: flex;margin-top: 4px;">
                            <p class="table-normal" style="flex: 5;">Total Score</p>
                            <div style="width: 4px;"></div>
                            <p class="table-normal" style="flex: 1;">{{ totalScore }}</p>
                        </div>
                    </div>
                </div>
                <div class="empty-line" style="margin-top: 10px;"></div>
                <p class="content-label" style="margin-top: 10px;">Comment</p>
                <div class="empty-line"></div>
                <div class="comment">
                    {{ comment }}
                </div>
            </div>
        </div>
        <el-button type="primary" size="small" style="margin-bottom: 20px; position: absolute" @click="exportPDF">
            Download</el-button>
    </div>
</template>

<script>
import { getReviewEntryV1 } from '../../api/eae'
import "../../assets/common/font.css";
import * as echarts from "echarts"


export default {
    name: "index",
    data() {
        return {
            entry_id: this.$route.query.id,
            entryInfo: {},
            totalScore: 0,
            comment: '',
            project_code: '',
            score_date: '',
            dimensions: [],
            showProjectName: '',
            entryType: '',
            studentName: ''
        }
    },
    mounted() {
        // if (true) {
        //     this.entry_id = '64a187afa4640bd5028b4569'
        // }
        this.fetchData()
    },
    methods: {
        fetchData() {
            getReviewEntryV1(this.entry_id).then((res) => {
                let result = res.data.data
                this.entryInfo = result

                if (result.student.student_lastName_pinyin) {
                    this.studentName =
                        result.student.student_givenName_pinyin.charAt(0).toUpperCase() + 
                        result.student.student_givenName_pinyin.slice(1) +
                        " " +
                        result.student.student_lastName_pinyin.charAt(0).toUpperCase() + 
                        result.student.student_lastName_pinyin.slice(1);
                } else {
                    this.studentName =
                        result.student.student_lastName.charAt(0).toUpperCase() + 
                        result.student.student_lastName.slice(1) +
                        " " +
                        result.student.student_givenName.charAt(0).toUpperCase() + 
                        result.student.student_givenName.slice(1);
                }

                this.project_code = result.projectManage.project_code

                let publish_time = this.formatPublishTime(result)
                this.formatCertDate(publish_time);
                this.formatProjectName(publish_time, result.projectManage.show_name);

                if (this.project_code != '22-23EAE') {
                    if (result.application_type == '个人') {
                        this.entryType = 'Individual'
                    } else {
                        this.entryType = 'Team'
                    }
                } else {
                    this.formatShowEntryType(result.entry_type);
                }

                let entryScore = 0
                let bonus_points = 0;
                bonus_points = result.score.bonus_points
                let scoring_dimension = result.score.scoring_dimension
                let keys = Object.keys(scoring_dimension)
                for (let i = 0; i < keys.length; i++) {
                    let score = scoring_dimension[keys[i]]
                    entryScore = entryScore + parseInt(score)
                }
                this.comment = result.score.comment
                this.totalScore = (entryScore + bonus_points)

                this.$nextTick(() => {
                    this.initRadar()
                })
            })
        },

        initRadar() {

            let id = document.getElementById('echart-radar')
            let myCharts = echarts.init(id)

            let datas = []
            this.dimensions = this.entryInfo.score.dimensions
            this.dimensions.forEach((element) => {
                datas.push({
                    name: element.name,
                    score: element.real_score,
                    totalScore: element.value
                })
            })
            let colorList = ['#FF6450', '#FF6450'];
            let scoreList = datas.map((n) => { return n.score; });
            let nameList = [];
            datas.forEach((item) => {
                nameList.push({
                    name: item.name,
                    max: item.totalScore,
                    score: item.score
                });
            });
            let option = {
                radar: {
                    center: ['45%', '42%'], // 图表位置
                    radius: '60%', // 图表大小
                    // 设置雷达图中间射线的颜色
                    axisLine: {
                        lineStyle: {
                            color: '#333',
                            fontSize: 30
                        }
                    },
                    indicator: nameList,
                    // 雷达图背景的颜色，在这儿随便设置了一个颜色，完全不透明度为0，就实现了透明背景
                    splitArea: {
                        areaStyle: {
                            color: '#fff' // 图表背景的颜色
                        }
                    },
                    name: {
                        lineHeight: 18,
                        formatter: (labelName, raw) => {
                            return (
                                labelName
                            );
                        },
                        rich: {
                            score: {
                                color: colorList[0],
                                fontSize: 16
                            }
                        }
                    }
                },
                series: [
                    {
                        type: 'radar',
                        data: [
                            {
                                value: scoreList,
                                // name: '你的得分',
                                itemStyle: {
                                    color: colorList[0]
                                },
                                // label: {
                                //     show: false,
                                //     fontSize: 16,
                                //     position: 'right',
                                //     color: colorList[0],
                                //     formatter: function (params) {
                                //         return params.value;
                                //     }
                                // },
                                // areaStyle: {
                                //     color: colorList[0],
                                //     opacity: 0.2
                                // }
                            },

                        ]
                    }
                ]
            };
            myCharts.setOption(option)
            window.addEventListener('resize', function () {
                myCharts.resize()
            })
        },
        exportPDF() {
            this.$pdf(this.$refs.scoreContent, {
                name: new Date().getTime(),
                scale: 2,
                ignoreElements: (element) => {
                    if (element.className.indexOf("el-button") !== -1) {
                        return true;
                    }
                },
            });
        },

        formatPublishTime(result) {
            let application_type = result.application_type
            let temp_announce_date = -1
            let entry_date = result.projectManage.entry_date
            entry_date.sort(function (a, b) {
                return a.ddl_date - b.ddl_date
            })
            let submission_date = result.submission_date
            //应该是离create_time最近的一个
            for (let i = 0; i < entry_date.length; i++) {
                let item = entry_date[i]
                let ddl_date = parseInt((item.ddl_date) / 1000)
                let announce_date = parseInt((item.announce_date) / 1000)
                let cert_date = -1
                if (item.cert_date && item.cert_date !='') {
                    cert_date = parseInt((item.cert_date) / 1000)
                } else {
                    cert_date = announce_date
                }
                //个人
                if (application_type == '个人' && item.app_type == 'indie' && submission_date < ddl_date) {
                    temp_announce_date = cert_date
                    break
                }
                //团队
                if (application_type == '团队' && item.app_type == 'team' && submission_date < ddl_date) {
                    temp_announce_date = cert_date
                    break
                }
            }
            return temp_announce_date
        },
        //就固定日期写死，2023年10月31日之前，老模板；2023年11月1日之后，动态调整
        formatProjectName(publish_time, showName) {
            if (publish_time <= 1698767999) {
                this.showProjectName = "22-23";
                return;
            }
            this.showProjectName = showName.replace('EAE', '')
        },

        formatCertDate(publish_time) {
            let publishTime = publish_time * 1000;
            var date = new Date(publishTime);
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            this.score_date = this.formatMonthToEng(month) + " " + day + ", " + year;
        },
        formatShowEntryType(type) {
            switch (type) {
                case "team_exp":
                    this.entryType = "Team Work & Experience";
                    break;
                case "team_story":
                    this.entryType = "Team Storyline";
                    break;
                case "indie_story":
                    this.entryType = "Individual Storyline";
                    break;
                case "indie_exp":
                    this.entryType = "Individual Work & Experience";
                    break;
            }
        },
        formatMonthToEng(month) {
            switch (month) {
                case 1:
                    return "January";
                case 2:
                    return "February";
                case 3:
                    return "March";
                case 4:
                    return "April";
                case 5:
                    return "May";
                case 6:
                    return "June";
                case 7:
                    return "July";
                case 8:
                    return "August";
                case 9:
                    return "September";
                case 10:
                    return "October";
                case 11:
                    return "November";
                case 12:
                    return "December";
            }
        },
    }
}
</script>
<style scoped lang="less">
.table-normal-header {
    color: white;
    background-color: #FF6450;
    opacity: 0.8;
    text-align: center;
    padding: 3px 0px;
    box-sizing: border-box;
}

.table-normal {
    border: 1px solid #FF6450;
    background-color: white;
    color: #FF6450;
    opacity: 0.8;
    text-align: center;
    padding: 3px 0px;
    box-sizing: border-box;
}

.transcript-bg {
    position: absolute;
    width: 267mm;
    height: auto;
    object-fit: cover;
}

.score-container {
    position: absolute;
    -webkit-text-size-adjust: 100% !important;
    font-variant: normal;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    width: 267mm;
    height: 380mm;

    .project_code {
        color: #333333;
        font-size: 28px;
        font-family: Arial, Helvetica, sans-serif;
    }

    .content-label {
        color: #333333;
        font-size: 20px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }

    .score-date {
        text-align: right;
        margin-top: 10px;
        color: #333333;
        font-size: 18px;
        font-family: Arial, Helvetica, sans-serif;
    }

    .empty-line {
        width: 100%;
        height: 2px;
        background-color: #333333;
        margin-top: 15px;
    }

    .entry-content {
        margin-top: 10px;
        color: #333333;
        font-size: 18px;
        font-family: Arial, Helvetica, sans-serif;
    }

    .comment {
        margin-top: 15px;
        color: #333333;
        font-size: 18px;
        line-height: 28px;
        font-family: Arial, Helvetica, sans-serif;
    }

    .echart_size {
        width: 600px;
        height: 100%;
    }

}
</style>