<template>
  <div>
    <div class="content-top">
      <div class="flex items-center mb-4 mt-3 justify-between">
        <div class="flex items-center">
          <i class="el-icon-arrow-left font-bold text-xl cursor-pointer" style="color: #999999" @click="goBack" />
          <p class="text-xl ml-2 font-bold" style="color: #ff8e3b">
            {{ project_name }} - Student Registrations
          </p>
        </div>
      </div>
      <div style="flex: 1;display: flex;flex-direction: row-reverse;margin-top: 10px;">
        <el-button type="danger" @click="btnCreate">New Registration</el-button>
        <el-button type="danger" @click="doEditTeam()" v-if="user_type == 'advisor'" style="margin-right: 20px;">{{
          enableTeam == false ? 'Assign Teams' : 'Save Teams' }}</el-button>
      </div>
    </div>
    <div style="padding: 20px;">
      <el-table :data="dataList" style="font-size: 12px;box-sizing: border-box;" empty-text="No Data"
        :highlight-current-row="false" :highlight-selection-row="false" :header-cell-class-name="'custom-head-cell'"
        :cell-style="tableCellstyle" :row-class-name="'custom-row-bg'">
        <!--            <el-table-column prop="index" type="index" label="Index" width="100px"></el-table-column>-->
        <el-table-column prop="application_project" label="Student">
          <template slot-scope="scope">
            {{ scope.row.student.student_givenName_pinyin.charAt(0).toUpperCase() + scope.row.student.student_givenName_pinyin.slice(1) + ' ' + 
               scope.row.student.student_lastName_pinyin.charAt(0).toUpperCase() + scope.row.student.student_lastName_pinyin.slice(1) }}
          </template>
        </el-table-column>
        <el-table-column prop="student.student_email" label="Email"></el-table-column>
        <el-table-column prop="student.student_graduation" label="Grad Year" width="120px"></el-table-column>
        <el-table-column prop="" label="Registered Through" width="220px" v-if="user_type == 'advisor'">
          <template slot-scope="scope">
            {{ scope.row.advisor ? (scope.row.advisor.contact_enName ? 
              scope.row.advisor.contact_enName :
              scope.row.advisor.contact_givenName + ' ' + scope.row.advisor.contact_lastName) :
              'Independent Registration' }}
          </template>
        </el-table-column>
        <el-table-column prop="application_status" label="Status" width="120px">
          <template slot-scope="scope">
            {{ scope.row.application_status === '无需缴费' || scope.row.application_status === '已缴费' ? 'Paid' : scope.row.application_status === '已取消' ? 'Canceled': 'Pending Payment'  }}
          </template>
        </el-table-column>

        <el-table-column prop="" label="Team" width="180px" v-if="user_type == 'advisor'">
          <template slot-scope="scope">
            <el-select v-model="scope.row.application_team_code" :disabled="!checkEnableEditTeam(scope.row)"
              placeholder="Please select team" style="width: 160px;"
              @change="handleApplicationTeamCodeChanged($event, scope.row._id.$id)">
              <el-option v-for="(code, index) in 10" :key="index" :label="code" :value="code"></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="Operation">
          <!-- <template slot-scope="scope">
            <el-button v-if="scope.row.application_status === '待缴费'" type="danger"
              @click="btnRemove(scope.row)">Remove</el-button>
          </template> -->
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="isShowDialog" title="New Registration" width="860px">
      <div>
        <el-form :inline="true" ref="form" :rules="rules" :model="formData" label-width="180px">
          <el-form-item label="Given Name (Native)" prop="givenName">
            <el-input v-model="formData.givenName" placeholder="Please input" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Last Name (Native)" prop="lastName">
            <el-input v-model="formData.lastName" placeholder="Please input" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Given Name (EN)" prop="givenName_pinyin">
            <el-input v-model="formData.givenName_pinyin" placeholder="Please input" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Last Name (EN)" prop="lastName_pinyin">
            <el-input v-model="formData.lastName_pinyin" placeholder="Please input" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="formData.email" placeholder="Please input" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Year of Graduation" prop="graduation">
            <el-select v-model="formData.graduation" clearable placeholder="Please select" style="width: 220px;">
              <el-option v-for="item in yearOpt" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div style="width: 100%; text-align: center; margin-top: 30px">
          <el-button size="medium" @click="isShowDialog = false">Cancel</el-button>
          <el-button type="primary" size="medium" @click="saveInfo(formData)">Confirm</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  batchToUpdateApplicationTeamCode, createApplicationByProjectAndAccount,
  getStudentAppsByAccount, removeApplication
} from '../../api/eae'
import {
  getUserType, getAccountId
} from '../../utils/store'

export default ({
  name: 'index',
  data() {
    return {
      enableTeam: false,
      user_type: getUserType(),
      project_id: this.$route.query.project_id,
      project_code: this.$route.query.project_code,
      project_name: this.$route.query.project_name,
      dataList: [],
      year: null,
      yearOpt: [],
      isShowDialog: false,
      formData: {
        project_code: '',
        account_id: '',
        lastName: '',
        givenName: '',
        lastName_pinyin: '',
        givenName_pinyin: '',
        graduation: '',
        email: '',
        student_guardian_first_email: ''
      },
      rules: {
        givenName: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        lastName: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        account_nameEN: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        lastName_pinyin: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        givenName_pinyin: [
          { required: true, message: "Please choose", trigger: "change" },
        ],
        graduation: [
          { required: true, message: "Please choose", trigger: "change" },
        ],
        email: [
          { required: true, message: 'Please input the email', trigger: 'blur' },
          { type: 'email', message: 'Please input the correct email address', trigger: ['blur', 'change'] }
        ]
      },
      isShowCDialog: false,
      applications_teams: null
    }
  },
  mounted() {
    const currentYear = new Date().getFullYear()
    this.yearOpt = []
    for (let i = 0; i < 6; i++) {
      this.yearOpt.push(currentYear + i)
    }
    this.fetchData()
  },
  methods: {

    goBack() {
      this.$router.go(-1)
    },

    checkEnableEditTeam(application) {
      if (this.enableTeam == false) {
        return false
      }
      if (application.application_status === '无需缴费' || application.application_status === '已缴费') {
        return true
      }
      return false
    },

    doEditTeam() {
      if (this.enableTeam == false) {
        this.enableTeam = true
        this.applition_teams = new Map()
        return
      }
      this.enableTeam = false
      let new_codes = [];
      this.applications_teams.forEach((value, key) => {
        console.log('code : ' + value + ' , id :' + key)
        new_codes.push({
          id: key,
          team_code: value
        })
      });
      if (new_codes.length <= 0) {
        return
      }
      batchToUpdateApplicationTeamCode(new_codes).then((res) => {
        this.$message.success('Team updated successful.')
        this.fetchData()
      })
    },

    handleApplicationTeamCodeChanged(code, id) {
      if (this.applications_teams == null) {
        this.applications_teams = new Map()
      }
      this.applications_teams.set(id, code)
    },

    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getStudentAppsByAccount(getAccountId(), this.project_id).then((res) => {
        this.dataList = res.data
        loading.close()
      })
    },
    tableCellstyle() {
      return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
    },
    btnCreate() {
      this.isShowDialog = true
    },
    saveInfo(data) {
      this.formData.account_id = getAccountId()
      this.formData.project_code = this.project_code
      this.$refs.form.validate((val) => {
        if (val) {
          createApplicationByProjectAndAccount(this.formData).then((res) => {
            if (res.data._id.$id) {
              this.fetchData()
            } else {
              this.$message.error("Registration Failed");
            }
            /*this.$router.replace({
              path: '/form/success',
              query: {
                id: res.data._id.$id,
                project: 'registration'
              }
            })*/
            this.isShowDialog = false
          });
        }
      });
    },
    btnRemove(data) {
      this.$confirm(
        'Are you sure you want to remove ' + data.student.student_givenName_pinyin.charAt(0).toUpperCase() + data.student.student_givenName_pinyin.slice(1) + ' ' + data.student.student_lastName_pinyin.charAt(0).toUpperCase() + data.student.student_lastName_pinyin.slice(1) + ' from the ' + data.application_project + '？',
        "Remove Students",
        {
          type: "warning",
          confirmButtonText: "Confirm",
        }
      ).then(() => {
        removeApplication(data._id.$id).then((res) => {
          this.$message.success('Registration Removed Successfully')
          this.fetchData()
        })
      }).catch(() => { });
    }
  }
})
</script>
<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
  background: #FAE3E4;
}

/deep/ .el-table tbody .cell {
  font-family: Ubuntu-Light;
}

/deep/ .el-table .custom-head-cell {
  background: var(--c-primary);
  color: white;
  text-align: center;
  border: 1px solid white;
  box-sizing: border-box;
  font-family: Ubuntu-Bold;
}

.content-top {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0 20px;
}

.create_btn {}
</style>